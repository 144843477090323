import { Box, Button, Container, Paper, Typography } from '@mui/material'
import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import { HasilPencarian } from '../components/HasilPencarian';
import { db } from '../config/firebase';
import { titleCase } from "title-case";
import { LoadingItem } from '../components/LoadingItem';


export const KategoriSatuan = () => {

  const [listProduk,setListProduk] = useState(null);
  const [statusLoading, setStatusLoading] = useState(false)
  const [loadDatabase, setLoadDatabase] = useState(null)

  let {kategorisatuan} = useParams();
  console.log(kategorisatuan)
  
//   const q = query(collection(db, "products"), where("kategori", "==", kategorisatuan.toLowerCase()), limit(3));
  
//   const loadData = async () =>{
//     const querySnapshot = await getDocs(q);
//     console.log('sedang mencari data',querySnapshot,listProduk)
//   setListProduk(querySnapshot.docs.map((doc)=>({
//     ...doc.data(), id:doc.id

// })))
// console.log('selesai mencari data',querySnapshot,listProduk)

//   }
  
  
//   useEffect(()=>{
//     console.log('Sedang coba')
//     loadData()
//   },[])



const [postTerakhir,setPostTerakhir] = useState();

useEffect(()=>{
  // getProduk()
  if (listProduk !== null){
      // console.log('Loading...')
      setStatusLoading(false)
  }


},[listProduk])


const loadData = async () =>{
  const q = query(collection(db, "products"), orderBy("namaProduk"), where("kategori", "==", kategorisatuan.toLowerCase()), limit(10))
  const querySnapshot = await getDocs(q);
  console.log('sedang mencari data',querySnapshot,listProduk)
  let arraySementara = []
//   setListProduk(querySnapshot.docs.map((doc)=>({
//     ...doc.data(), id:doc.id

// })))

querySnapshot.forEach((doc)=>{
arraySementara.push({...doc.data(),id:doc.id});
});

const dataTerakhir = querySnapshot.docs[querySnapshot.docs.length -1];
setPostTerakhir(dataTerakhir)
setListProduk(arraySementara);

console.log('selesai mencari data',querySnapshot,listProduk)

}


useEffect(()=>{
  if (listProduk === null){
      console.log('Loading...')
      setStatusLoading(true)
  }
  // const q = query(collection(db, "products"), where("kategori", "==", kategorisatuan.toLowerCase()), limit(3))
  // const unsub = onSnapshot(q,(QuerySnapshot)=>{
  //   let arraySementara = [];
    
  //   QuerySnapshot.forEach((doc)=>{
  //   arraySementara.push({...doc.data(),id:doc.id});
  // });
  
//   const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
//   console.log("Data Terakhir adalah ",dataTerakhir)
//   setPostTerakhir(dataTerakhir)
//   setListProduk(arraySementara);

// })
loadData()




},[]);




const fetchMore = async () =>{





      setStatusLoading(true)
      console.log('Panjang data sudah')
      
      const q = query(collection(db, "products"), orderBy("namaProduk"), where("kategori", "==", kategorisatuan.toLowerCase()), limit(10),startAfter(postTerakhir))
    //   const unsub = onSnapshot(q,(QuerySnapshot)=>{

    //     console.log('Ukuran query Snapshot 222 ', QuerySnapshot.size)
    //     const sisaDatabase = QuerySnapshot.size;
    //       if (sisaDatabase !== 0){

    //         let arraySementara = [];
            
    //         QuerySnapshot.forEach((doc)=>{
    //         arraySementara.push({...doc.data(),id:doc.id});
    //       });
          
    //       const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
    //       console.log("Data Terakhir adalah ",dataTerakhir)
    //       setPostTerakhir(dataTerakhir)
    //       setListProduk((listProduk)=>[...listProduk,...arraySementara]);
    //       setStatusLoading(false)
        
    //       }else{
    //         setLoadDatabase(true)
    //       }
        
    // })

    const querySnapshot = await getDocs(q);

            const sisaDatabase = querySnapshot.size;
          if (sisaDatabase !== 0){

            let arraySementara = [];
            
            querySnapshot.forEach((doc)=>{
            arraySementara.push({...doc.data(),id:doc.id});
          });
          
          const dataTerakhir = querySnapshot.docs[querySnapshot.docs.length -1];
          console.log("Data Terakhir adalah ",dataTerakhir)
          setPostTerakhir(dataTerakhir)
          setListProduk((listProduk)=>[...listProduk,...arraySementara]);
          setStatusLoading(false)
        
          }else{
            setLoadDatabase(true)
          }
        

    setStatusLoading(false)

}



  return (
    <>
    {statusLoading&&<LoadingItem/>}
        <Container>
                <Box sx={{m:2}}>
                    <Typography>Produk dengan kategori {titleCase(kategorisatuan)}</Typography>
                </Box>


            <Box sx={{mb:10,mt:2}}>
            {listProduk?.map((produk)=>{
        return (
          <HasilPencarian 
          key={produk.namaProduk}
          produk={produk}
          listProduk={listProduk}
          setListProduk={setListProduk}
        
        />)
      })}


     

      {!statusLoading  && !loadDatabase && <Button sx={{m:'auto', display:'block'}} onClick={fetchMore}>More</Button>}
      {loadDatabase&&<Typography sx={{mt:2, display:'block', textAlign:'center'}}>Semua data sudah berhasil ditampilkan</Typography>}

            </Box>

            

        </Container>
    </>
  )
}
