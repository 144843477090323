
import React, { useEffect, useState } from 'react'
import { HasilPencarian } from '../components/HasilPencarian'
import { KolomCari } from '../components/KolomCari'
import { getDocs,collection, query, orderBy, where, limit, startAt, endAt,onSnapshot, doc, startAfter, getCountFromServer  } from 'firebase/firestore'
import { auth, db } from '../config/firebase'
import { LoadingItem } from '../components/LoadingItem'
import { Info } from '../components/Info'
import { Box } from '@mui/system'
import { EtalaseProduk } from '../components/EtalaseProduk'
import { Button, Chip, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material'
import { useAuthState} from "react-firebase-hooks/auth";
import { useContext } from 'react';
import { AppContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"



export const Home = () => {
  const {produkCari,setProdukCari} = useContext(AppContext)

  const [statusLoading, setStatusLoading] = useState(false)
  const [listProduk,setListProduk] = useState(null);
  const [loadDatabase, setLoadDatabase] = useState(null)
  const [user, loading,error] = useAuthState(auth);

  const navigate = useNavigate()
  
  const {statusAkun} = useContext(AppContext)


  // useEffect(()=>{

    

  //   console.log('Status Akun ', statusAkun)
  
  //   if (!statusAkun){
  //     navigate('/profile')
  //   }
  

  // },[statusAkun])
  
  // const postsRef1 = collection(db,'products')
  // const postsRef = query(postsRef1, orderBy("namaProduk", "asc"))
  const [postTerakhir,setPostTerakhir] = useState();
  const [namaFetchMore, setNamaFetchMore] = useState(null)


  const getProduk = async (nama) =>{
    setNamaFetchMore(nama)
    setStatusLoading(true)
    console.log('Ketrigger')
    const postsRef1 = collection(db,'products')
    // const postsRef = query(postsRef1, where("namaProduk", ">=", nama), where("namaProduk", "<=", nama + "\uf8ff"), limit(5))
    // const postsRef = query(postsRef1, orderBy('namaProduk'), startAt(nama), endAt(nama+'\uf8ff'), limit(5))
    const postsRef = query(postsRef1, where("namaArray", "array-contains", nama), orderBy('namaProduk'), limit(3))

    const querySnapshot = await getDocs(postsRef)

    // if (querySnapshot.exists()) {
    //   console.log("Document data:", querySnapshot.data());
    //   alert('data ada')
    // } else {
    //   // doc.data() will be undefined in this case
    //   console.log("No such document!");
    //   alert('data tidak ada')
    // }

    let arraySementara = [];

    querySnapshot.forEach((doc)=>{
      if(doc.exists()){
        arraySementara.push({...doc.data(),id:doc.id});
        
      }else{
       console.log('Data yang dicari tidak tersedia')
      }
      
      });

      const dataTerakhir = querySnapshot.docs[querySnapshot.docs.length -1];
setPostTerakhir(dataTerakhir)
setListProduk(arraySementara);

console.log('selesai mencari data',querySnapshot,listProduk)

setStatusLoading(false)



  }

  

  useEffect(()=>{
    // getProduk()
    if (listProduk !== null){
        // console.log('Loading...')
        setStatusLoading(false)
    }


  },[listProduk])

  // useEffect(()=>{
  //   if (listProduk === null){
  //       console.log('Loading...')
  //       setStatusLoading(true)
  //   }
  //   const q = query(collection(db,"products"), orderBy("namaProduk"), limit(3))
  //   const unsub = onSnapshot(q,(QuerySnapshot)=>{
  //     let arraySementara = [];
      
  //     QuerySnapshot.forEach((doc)=>{
  //     arraySementara.push({...doc.data(),id:doc.id});
  //   });
  //   const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
  //   console.log("Data Terakhir adalah ",dataTerakhir)
  //   setPostTerakhir(dataTerakhir)
  //   setListProduk(arraySementara);
  
  // })
  
  // },[]);




  const fetchMore = () =>{
    // console.log('Load more')
    // const sisaDatabase = collection.size;
    // console.log('Collection size ', sisaDatabase,collection)




        setStatusLoading(true)
        console.log('Panjang data sudah')
        const postsRef1 = collection(db,'products')
        // const q = query(collection(db,"products"), orderBy("namaProduk"), limit(5),startAfter(postTerakhir))
        // const q = query(collection(db,"products"), orderBy("namaProduk"), limit(5),startAfter(postTerakhir))
        const q = query(postsRef1, where("namaArray", "array-contains", namaFetchMore), orderBy('namaProduk'), limit(5),startAfter(postTerakhir))
        const unsub = onSnapshot(q,(QuerySnapshot)=>{

          console.log('Ukuran query Snapshot 222 ', QuerySnapshot.size)
          const sisaDatabase = QuerySnapshot.size;
            if (sisaDatabase !== 0){

              let arraySementara = [];
              
              QuerySnapshot.forEach((doc)=>{
              arraySementara.push({...doc.data(),id:doc.id});
            });
            
            const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
            console.log("Data Terakhir adalah ",dataTerakhir)
            setPostTerakhir(dataTerakhir)
            setListProduk((listProduk)=>[...listProduk,...arraySementara]);
            setStatusLoading(false)
          
            }else{
              setLoadDatabase(true)
            }
          
      })

      setStatusLoading(false)

  }

  const clearPencarian = () =>{
    setProdukCari('')
    setListProduk(null)
  }





  

  return (
    <>



      
      {statusLoading&&<LoadingItem/>}
      {statusLoading&&<CircularProgress />}
      <Info/>
      <KolomCari getProduk={getProduk} setListProduk={setListProduk}/>

      <EtalaseProduk/>
      
      <Box sx={{mb:20}}>
      {listProduk&&<Container><Stack  alignItems="flex-end" ><Chip label="Clear Pencarian" variant="outlined" color="error" size="small"sx={{mt:1, mr:1}} onClick={()=>clearPencarian()}/></Stack></Container>}
      {listProduk?.map((produk)=>{
        return (
          <motion.div animate={{ y: 10 }} whileHover={{ scale: 1.02 }}>
          <HasilPencarian 
          produk={produk}
          listProduk={listProduk}
          setListProduk={setListProduk}
        /></motion.div>)
      })}

      


      
      {!statusLoading  && !loadDatabase && listProduk?.length > 0 &&<Button sx={{m:'auto', display:'block'}} onClick={fetchMore}>More</Button>}
      {loadDatabase&&<Typography sx={{mt:2, display:'block', textAlign:'center'}}>Semua data sudah berhasil ditampilkan</Typography>}
      </Box>
      
   


    
    </>
  )
}
