import React from 'react';  
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
export const Panduan = () => {
  return (
    <Container sx={{mt:2}}>
    <Typography fontWeight={'bold'} variant="h5" textAlign={'center'}>Panduan Galaxy</Typography>

    {/* <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant='body1'>Ketentuan Pembelian di Toko Langsung / Pembelian Offline</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body1'>
          Untuk setiap customer yang dari Online bisa mendapatkan diskon potongan harga sebesar 2% dari harga SRP khusus untuk pembelian ditoko langsung, dan khusus dengan pembayaran metode tunai, transfer dan debit.
        </Typography>
      </AccordionDetails>
    </Accordion>
     */}
    <Box sx={{mt:2}}>

    <Stack direction={'row'} gap={1}  sx={{mb:1}}>
        <BookmarkBorderIcon></BookmarkBorderIcon>
        <Typography sx={{textDecoration:'none'}} variant="body" component={Link} to="/about">Tentang Galaxy</Typography>
      </Stack>

      <Stack direction={'row'} gap={1} sx={{mb:1}}>
        <BookmarkBorderIcon></BookmarkBorderIcon>
        <Typography sx={{textDecoration:'none'}} variant="body" component={Link} to="/term-condition">Term and Condition</Typography>
      </Stack>

      <Stack direction={'row'} gap={1}  sx={{mb:1}}>
        <BookmarkBorderIcon></BookmarkBorderIcon>
        <Typography sx={{textDecoration:'none'}} variant="body" component={Link} to="/crew-store">Crew Store</Typography>
      </Stack>

      <Stack direction={'row'} gap={1}  sx={{mb:1}}>
        <BookmarkBorderIcon></BookmarkBorderIcon>
        <Typography sx={{textDecoration:'none'}} variant="body" component={Link} to="/desain-grafis">Desain Grafis / Konten Kreator</Typography>
      </Stack>

      <Stack direction={'row'} gap={1} sx={{mb:1}}>
        <BookmarkBorderIcon></BookmarkBorderIcon>
        <Typography sx={{textDecoration:'none'}} variant="body" component={Link} to="/ulasan-google">Minta Ulasan Google Kepada Customer</Typography>
      </Stack>



     </Box>
  </Container>
  )
}
