import { AppBar, Avatar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../config/firebase';
import { useAuthState} from "react-firebase-hooks/auth";
import { signOut } from 'firebase/auth';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useEffect } from 'react';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import CalculateIcon from '@mui/icons-material/Calculate';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PrintIcon from '@mui/icons-material/Print';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GroupIcon from '@mui/icons-material/Group';


export const Header = () => {

  const navigate = useNavigate()
  const [user, loading,error] = useAuthState(auth);

  console.log(user)

  const signUserOut = async () =>{
      await signOut(auth)
      navigate('/')
      window.location.reload()
  }




  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={()=>{toggleDrawer(anchor, false)}}
      onKeyDown={()=>{toggleDrawer(anchor, false)}}
    >
      <List>
 
          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/etalase">
              <ListItemIcon>
               <FeaturedPlayListIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Etalase'} />
            </ListItemButton>
          </ListItem>

          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/hitung-cuan">
              <ListItemIcon>
               <CalculateIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Hitung Cuan'} />
            </ListItemButton>
          </ListItem>

          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/kartu-kredit">
              <ListItemIcon>
               <CreditCardIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Info Kartu Kredit'} />
            </ListItemButton>
          </ListItem>


          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/cetak-label">
              <ListItemIcon>
               <PrintIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Cetak Label'} />
            </ListItemButton>
          </ListItem>

          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/format-order">
              <ListItemIcon>
               <AddShoppingCartIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Format Order'} />
            </ListItemButton>
          </ListItem>

          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/panduan">
              <ListItemIcon>
               <MenuBookIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Panduan'} />
            </ListItemButton>
          </ListItem>


      </List>


      <Divider />

      <List>


          <ListItem  disablePadding>
            <ListItemButton component={Link} to="/settings" >
              <ListItemIcon>
               <SettingsIcon /> 
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </ListItemButton>
          </ListItem>

          {user?.email === 'galaxycameraid@gmail.com'&&(
            <>
            <ListItem  disablePadding>
            <ListItemButton component={Link} to="/settings" >
              <ListItemIcon>
               <GroupIcon /> 
              </ListItemIcon>
              <ListItemText primary={'List Pengguna'} />
            </ListItemButton>
          </ListItem>
          </>)}

          {user&&<ListItem  disablePadding>
      <ListItemButton onClick={signUserOut}>
        <ListItemIcon>
         <LogoutIcon /> 
        </ListItemIcon>
        <ListItemText primary={'Log Out'} />
      </ListItemButton>
    </ListItem>
          }


      </List>










    </Box>
  );


  return (
    <>
      
      <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          
          >
            {list('left')}
          </Drawer>
        <AppBar position="static" >
          <Toolbar >
            
            
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            
            onClick={toggleDrawer('left', true)}

          >
            <MenuIcon />
          </IconButton>

            
            {/* <Typography variant="h6" sx={{ flexGrow: 1 ,textDecoration:"none", color:"white"}} component={Link} to="/" >
                Galaxy App v.2
            </Typography> */}

            <Box justifyContent="center"  alignItems="center" sx={{flexGrow: 1, m:'auto'}} component={Link} to="/">
              <img style={{ alignItems:'center', justifyContent:'center', display:'block'}} src="../logo.png" height={21}></img>
            </Box>
   


            <Stack direction='row' gap={1} justifyContent="center" justifyItems="center" alignItems={'center'}>
              {!user&&<Button color="inherit" component={Link}  to="/login" size="small">Login</Button>}
              {user&&(
              <>
              <Box>
              <Typography variant="body">{user?.displayName}</Typography>
              </Box>

              <Avatar alt={user?.displayName} src={user?.photoURL} component={Link} to="/profile" sx={{ textDecoration:"none", color:"white" }}/>
              </>
              )}
            </Stack>

          </Toolbar>
        </AppBar>

    </>
  )
}
