import { Alert, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useState } from 'react'
import { useAuthState} from "react-firebase-hooks/auth";
import { auth, db } from '../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import {startAfter, limit, addDoc, collection, doc, getDoc, getDocs, query, setDoc, where,orderBy } from 'firebase/firestore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../App';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TaskAltIcon from '@mui/icons-material/TaskAlt';



export const Profile = () => {
  const [user, loading,error] = useAuthState(auth);
  const {statusAkun} = useContext(AppContext)

  const postsRef1 = collection(db,'users')

  const navigate = useNavigate()

  const [tombolVerifikasi, setTombolVerifikasi] = useState(false)
  const [statusVerifikasi, setStatusVerifikasi] = useState(false)
  const [data,setData] = useState(null)

const loadProfile =  async () =>{
  const docRef = doc(db, "users", user.email);
  const docSnap = await getDoc(docRef);

  console.log('Hasil data pencarian ', docSnap.data())

  const status = docSnap.data()
  setStatusVerifikasi(status.status)
  setData(status)



  if (docSnap.exists()) {
    setTombolVerifikasi(true)
  } else {
    setTombolVerifikasi(false)
  }
   

}


useEffect(()=>{

  loadProfile()

},[user])





const requestVerifikasi = async () =>{

  // const docRef = doc(db, "users", user.email);
  // const docSnap = await getDoc(docRef);






  const userRef = collection(db, "users")
  await setDoc(doc(userRef, user.email),{
    nama:user?.email,
    id:uuidv4(),
    status:false,
    admin:false
  })

  loadProfile()
  alert('Request Berhasil Terkirim')



}


const [listProduk,setListProduk] = useState(null);
const [statusLoading, setStatusLoading] = useState(false)
const [loadDatabase, setLoadDatabase] = useState(null)

// // let {brand} = useParams();
// console.log(brand)


const [postTerakhir,setPostTerakhir] = useState();

useEffect(()=>{
  // getProduk()
  if (listProduk !== null){
      // console.log('Loading...')
      setStatusLoading(false)
  }


},[listProduk])


const loadData = async () =>{
  const q = query(collection(db, "duties"), orderBy("duty"), where("email", "==", user.email?.toLowerCase()), limit(10))
  const querySnapshot = await getDocs(q);
  console.log('sedang mencari data',querySnapshot,listProduk)
  let arraySementara = []
//   setListProduk(querySnapshot.docs.map((doc)=>({
//     ...doc.data(), id:doc.id

// })))

querySnapshot.forEach((doc)=>{
arraySementara.push({...doc.data(),id:doc.id});
});

const dataTerakhir = querySnapshot.docs[querySnapshot.docs.length -1];
setPostTerakhir(dataTerakhir)
setListProduk(arraySementara);

console.log('selesai mencari data',querySnapshot,listProduk)

}


useEffect(()=>{
  if (listProduk === null){
      console.log('Loading...')
      setStatusLoading(true)
  }

loadData()




},[]);





  console.log(listProduk)

  return (
    <>
      <Container>
        <Paper sx={{m:1, mt:2}} elevation={3}>
          <Box sx={{p:2}}>
            <Typography variant="h6">Profil</Typography>
            <Typography variant="body2">Nama : {user?.displayName}</Typography>
            <Typography variant="body2">Email : {user?.email} </Typography>
            <Typography variant="body2">Status Akun : {data?.admin?'Admin':'Basic'} </Typography>
            <Divider sx={{m:1}}/>
            {!statusAkun?<Alert severity="error" >Akun Anda Belum Terverifikasi, Silahkan Request Verifikasi Terlebih Dahulu</Alert>:<Alert severity="info">Akun Anda Telah Berhasil Terverifikasi</Alert>}
            <Stack direction='row' justifyContent={'space-between'} sx={{mt:2}}>
              
              <Typography variant="body2">Status Verifikasi : {statusVerifikasi?'Berhasil':'Pending'}</Typography>
              <Button disabled={tombolVerifikasi} variant='outlined' onClick={()=>{requestVerifikasi()}}>{tombolVerifikasi?'Verifikasi Terkirim':'Request Verifikasi'}</Button>
            </Stack>
            
            <Divider sx={{mt:2,mb:2}}></Divider>
            <Stack direction={'row'} gap={1} sx={{color:'#01579b'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Tanggung Jawab</Typography>
            <AssignmentIndIcon></AssignmentIndIcon>
            </Stack>



            <Box sx={{mb:10,mt:2}}>
            {listProduk?.map((produk)=>{
        return (
          <>
          <Stack direction={'row'} gap={1} alignItems='center '>
          <TaskAltIcon sx={{color:'#01579b'}} />
          <Typography variant="body2">{produk.duty}</Typography>
          </Stack>
         </>
         
         )
      })}



            </Box>



          </Box>
        </Paper>
      </Container>
    </>
  )
}
