import { Alert, Box, Button, Divider, FormControlLabel, FormGroup, getCheckboxUtilityClass, IconButton, Paper, Stack, Switch, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { auth, db } from '../config/firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthState } from 'react-firebase-hooks/auth';


export const Setting = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const postsRef1 = collection(db,'users')
  const postsRef = query(postsRef1, orderBy("nama", "asc"))
  const [listProduk,setListProduk] = useState(null);
  const [statusLoading, setStatusLoading] = useState(false)
  const [user, loading,error] = useAuthState(auth);


    //Metode Baru
    useEffect(()=>{
      if (listProduk === null){
          console.log('Loading...')
          setStatusLoading(true)
      }

      const q = query(postsRef)
      const unsub = onSnapshot(q,(QuerySnapshot)=>{
        let arraySementara = [];
        
        QuerySnapshot.forEach((doc)=>{
        arraySementara.push({...doc.data(),id:doc.id});
      });
      const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
      console.log("Data Terakhir adalah ",dataTerakhir)
      setListProduk(arraySementara);
    
    })
    
    },[]);



    const cekListStatus =  index => async (e) =>{
      console.log(e)
      let newArr = [...listProduk];
      console.log(newArr[index])
      newArr[index]['status'] = e.target.checked;

      setListProduk(newArr)


      const editRef = doc(db, 'users', newArr[index]['nama'])

      await updateDoc(editRef, {
      ...newArr[index]
      });

    }

    const cekListAdmin =  index => async (e) =>{
      console.log(e)
      let newArr = [...listProduk];
      console.log(newArr[index])
      newArr[index]['admin'] = e.target.checked;

      setListProduk(newArr)


      const editRef = doc(db, 'users', newArr[index]['nama'])

      await updateDoc(editRef, {
      ...newArr[index]
      });

    }


    const hapusAkun =  index => async (e) =>{
      let newArr = [...listProduk];

      
      const postRef = await deleteDoc(doc(db,"users",newArr[index]['nama']));

      console.log('Berhasil dihapus', postRef)


    }


  return (
    <>
        <Container>
            <Paper sx={{mt:2, p:2, mb:50
            }} elevation={3} >
            

            {/* <Alert severity="info">Akun Sudah Terverifikasi</Alert> */}

            {user?.email === 'galaxycameraid@gmail.com'?(
              <>
              <Typography variant="h6">List Akun Pengguna App</Typography>
              {listProduk?.map((produk,index)=>{
                return(
                  <Box sx={{mt:2}} key={produk.nama}>
                    <Typography variant="body">{produk.nama}</Typography>
                    <FormGroup>
                    <Stack direction={'row'} justifyContent="space-between">
                      <FormControlLabel control={<Switch checked={produk.status} value={produk.status}  onChange={cekListStatus(index)} name={produk.nama} />} label="Verifikasi Akun"/>
                      <Divider orientation="vertical" flexItem sx={{m:1}}/>
                      <FormControlLabel control={<Switch checked={produk.admin} value={produk.admin} onChange={cekListAdmin(index)} name={produk.nama} />} label="Jadikan Admin" />
                      <Divider orientation="vertical" flexItem sx={{m:1}}/>
                      <IconButton aria-label="delete" size="small"  onClick={hapusAkun(index)}>
                        <DeleteIcon fontSize="inherit"/>
                      </IconButton>
                    </Stack>
                    </FormGroup>
                  <Divider sx={{m:2}}/>
                </Box>
                )
              })}
              </>
              ):(
                <>
                  <Typography>Selamat datang di Akun {user?.displayName}</Typography>
                </>
              )}
                



            </Paper>
        </Container>
    </>
  )
}
