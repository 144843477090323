import { Button, Dialog, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { deleteDoc } from 'firebase/firestore';
import { LoadingItem } from './LoadingItem';


export const Konfirmasi2 = (props) => {

    const [loadingStatus,setLoadingStatus] = useState(false)
    

    const tutupModal = () =>{
        props.setStatusModalHapus((prev)=>
        ({
            ...prev,
            statusModal:false
        }))
    }

    
    const hapusProduk = async () =>{
        setLoadingStatus(true)
        await deleteDoc(props.statusModalHapus.postRef)
        props.setListProduk(props.listProduk.filter((pro)=> pro.id !== props.statusModalHapus.idProduk))
        setLoadingStatus(false)
        tutupModal()
    }

    


  return (
    <>
    {loadingStatus&&<LoadingItem />}
        <>
        <Dialog
        open={props.statusModalHapus.statusModal}
        onClose={()=>props.setStatusModalHapus((prev)=>
        ({
            ...prev,
            statusModal:false
        })
        )}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll='body'
      >
        <Stack sx={{m:2}}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small">
                <CloseIcon fontSize="inherit" onClick={()=>props.setStatusModalHapus((prev)=>
        ({
            ...prev,
            statusModal:false
        })
        )}/>
            </IconButton>
          </Stack>
            
            <DialogTitle id="alert-dialog-title" sx={{m:'auto'}}>
            {"Konfirmasi Hapus"}
            </DialogTitle>
            
            <Typography sx={{m:1, textAlign:'center'}}>Apakah Anda yakin ingin menghapus {props.statusModalHapus.namaProduk}?</Typography>

            <Stack>
                <Button sx={{m:1}}  onClick={()=>hapusProduk()} variant="contained">Hapus</Button>
                <Button sx={{m:1}} onClick={()=>tutupModal()}>Cancel</Button>
            </Stack>

        
            

        </Stack>
        

   
      </Dialog>
    </>
    </>
  )
}
