// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpelHC2A0MwzY4DMwH5av1ZPyaR0oiJ4M",
  authDomain: "galaxyappv2.firebaseapp.com",
  projectId: "galaxyappv2",
  storageBucket: "galaxyappv2.appspot.com",
  messagingSenderId: "470629112327",
  appId: "1:470629112327:web:a25f782fdbf2561dc568a2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app)
