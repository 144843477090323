import { Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
export const PanduanDesainGrafis = () => {
  return (
    <>
        <Container>
        <Stack sx={{mt:3, mb:20}}>

        <Stack direction={'row'} sx={{m:'auto', mb:2}} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} variant="h5" >PANDUAN DESAIN GRAFIS</Typography>
        </Stack>

        <Stack sx={{mt:2}}>
        <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>INFORMASI PRODUK</Typography>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Spesifikasi dan Perbandingan</Typography>
            <a href="https://cameradecision.com" target='_blank' rel="noreferrer">https://cameradecision.com</a>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Gambar Produk dan Spesifikasi</Typography>
            <a href="https://www.bhphotovideo.com" target='_blank' rel="noreferrer">https://www.bhphotovideo.com</a>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Hasil Foto, Spesifikasi, Review Produk</Typography>
            <a href="https://www.dpreview.com" target='_blank' rel="noreferrer">https://www.dpreview.com</a>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Review Produk</Typography>
            <a href="https://www.petapixel.com" target='_blank' rel="noreferrer">https://www.petapixel.com</a>
        </Stack>
        </Stack>

        <Stack sx={{mt:3}}>
          <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>WARNA</Typography>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Warna Gradient</Typography>
              <a href="https://uigradients.com/" target='_blank' rel="noreferrer">https://uigradients.com</a>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Color Palettes</Typography>
              <a href="https://colorhunt.co/" target='_blank' rel="noreferrer">https://colorhunt.co/</a>
          </Stack>

        </Stack>

        <Stack sx={{mt:3}}>
          <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>FONT</Typography>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>DaFont</Typography>
              <a href="https://www.dafont.com/" target='_blank' rel="noreferrer">https://www.dafont.com/</a>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Google Fonts</Typography>
              <a href="https://fonts.google.com/" target='_blank' rel="noreferrer">https://fonts.google.com/</a>
          </Stack>

        </Stack>

        <Stack sx={{mt:3}}>
          <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>RESOURCE / VECTOR / STOCK PHOTOS</Typography>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>FreePik</Typography>
              <a href="https://www.freepik.com/" target='_blank' rel="noreferrer">https://www.freepik.com/</a>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Pixabay</Typography>
              <a href="https://pixabay.com/" target='_blank' rel="noreferrer">https://pixabay.com/</a>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Pexels</Typography>
              <a href="https://www.pexels.com/" target='_blank' rel="noreferrer">https://www.pexels.com/</a>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Unsplash</Typography>
              <a href="https://unsplash.com/" target='_blank' rel="noreferrer">https://unsplash.com/</a>
          </Stack>

          

        </Stack>

        


        

        <Stack sx={{mt:3}}>
          <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>PRODUK DATABASE</Typography>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Data E-Commerce</Typography>
              <a href="https://docs.google.com/spreadsheets/d/1LJ_IdMWaRAxIEc7sLgFgirXkxeOVBQoi2r4IUawkBu4/edit#gid=0" target='_blank' rel="noreferrer">Data E-Commerce</a>
          </Stack>
        </Stack>

        <Stack sx={{mt:3}}>
          <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>KONTEN</Typography>

          <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
              <Typography variant='body2' fontWeight={'bold'}>Konten Pillar</Typography>
              <a href="https://docs.google.com/spreadsheets/d/1x9WUko3rfDiej1UMIzQnfBkvq6wxCB9aCNg7wFv-T-M/edit#gid=239554453" target='_blank' rel="noreferrer">Konten Pillar</a>
          </Stack>
        </Stack>






        </Stack>
        </Container>
    </>
  )
}
