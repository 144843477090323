import { Alert, Avatar, Button, Container, Divider, Fab, Grid, IconButton, Menu, MenuItem, Modal, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { useNavigate,useParams } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import dayjs from 'dayjs';
import { LoadingItem } from '../components/LoadingItem';
import { Konfirmasi } from '../components/Konfirmasi';
import ClearIcon from '@mui/icons-material/Clear';
import { Konfirmasi2 } from '../components/Konfirmasi2';
import { titleCase } from "title-case";
import { useContext } from 'react';
import { AppContext } from '../App';



var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime)


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:5,
  };


export const Brands = () => {
  const {akunMaster,brandProduk,setBrandProduk} = useContext(AppContext)

    const [statusLoading, setStatusLoading] = useState(false)

    const postsRef1 = collection(db,'brands')
    const postsRef = query(postsRef1, orderBy("nama", "asc"))


    // const [listProduk,setListProduk] = useState(null);
    const listProduk = brandProduk;
    const setListProduk = setBrandProduk;


    const [postTerakhir,setPostTerakhir] = useState();
    const [notifBerhasil,setNotifBerhasil] = useState(false)

    

    // const getProduk = async () =>{
    //   const data = await getDocs(postsRef)
    //    console.log('sedang mencari data',data,listProduk)
    //     setListProduk(data.docs.map((doc)=>({
    //       ...doc.data(), id:doc.id
  
    //   })))
    //   console.log('selesai mencari data',data,listProduk)
    // }


    //Metode Baru
    useEffect(()=>{
      if (listProduk === null){
          console.log('Data tidak tersedia, sedang di load ulang')
          console.log('Loading...')
          setStatusLoading(true)

          const q = query(postsRef)
          const unsub = onSnapshot(q,(QuerySnapshot)=>{
        let arraySementara = [];
        
        QuerySnapshot.forEach((doc)=>{
        arraySementara.push({...doc.data(),id:doc.id});
      });
      const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
      console.log("Data Terakhir adalah ",dataTerakhir)
      setPostTerakhir(dataTerakhir)
      setListProduk(arraySementara);
    
    })
      }

      
    
    },[]);
  
  


    let navigate = useNavigate()

    const menujuLink = (link) =>{
        navigate(link)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl(null);
    };


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [kategori2,setKategori] = useState({
        nama:'',
        id:''
    })

    const handleChange = (e) =>{
        console.log(e.target.value)
        setKategori((prev)=>({
            id:dayjs().format('YYYYMMDDmmssSSS'),
            [e.target.name]:e.target.value
        }))

        console.log(kategori2)
    }

    const handleSubmit = async (e) =>{
         e.preventDefault()
        
        console.log('Berhasil')
        kategori2.nama = kategori2.nama.toLowerCase()

    
          await addDoc(postsRef1,{
            ...kategori2
          });

          setNotifBerhasil(true)
          
          setTimeout(() => {
            setNotifBerhasil(false);
          }, 1000);

          setKategori((prev)=>({
            id:dayjs().format('YYYYMMDDmmssSSS'),
            ['nama']:''
        }))
       

        }


        useEffect(()=>{
            // getProduk()
            if (listProduk === null){
                console.log('Loading...')
                setStatusLoading(true)
            }
         
            console.log('Ini adalah listProduk ', listProduk)
        
          },[])
        
          useEffect(()=>{
            // getProduk()
            if (listProduk !== null){
                console.log('Loading...')
                setStatusLoading(false)
            }
         
             console.log('Ini adalah listProduk ', listProduk)
        
          },[listProduk])

                  // Berfungsi untuk konfirmasi hapus produk
    const [statusModalHapus,setStatusModalHapus] = useState({
        idProduk:null,
        namaProduk:null,
        postRef:null,
        statusModal:false,
    })



    const hapusProduk = async (produk,nama,listProduk) =>{
      const idHapus = produk
      const postRef = doc(db,"brands",idHapus);
      const q = query(collection(db, "products"), where("brand", "==", nama.toLowerCase()));
        const querySnapshot = await getDocs(q)
        console.log('Panjang dokumen ', querySnapshot.docs.length)
        const panjangDokumen = querySnapshot.docs.length
        if(panjangDokumen === 0){
            setStatusModalHapus({
              idProduk:produk,
              namaProduk:nama,
              postRef:postRef,
              statusModal:true,
            })
        }else{
          alert('Etalase tidak dapat dihapus karena terdapat produk terdaftar')
        }

      // setKonfirmasiHapus(true)
      // if(konfirmasiHapus){
      //   setStatusModalHapus(false)
      //   await deleteDoc(postRef)
      //   setListProduk(listProduk.filter((pro)=> pro.id !== idHapus))
      //   handleClose1()
      // }
    }

  //   const hapusProduk = (produk) =>{
  //     console.log('berhasil')
  //     setStatusModalHapus(true)
  //     // setKonfirmasiHapus(true)
      
  //     if(konfirmasiHapus){
  //       console.log('Produk berhasil terhapus dari sistem')
  //       setStatusModalHapus(false)
  //       deleteProduk(produk)
  //       handleClose1()
  //     }
      
  // }








    
  return (

    
    <>
      <Konfirmasi2 statusModalHapus={statusModalHapus} setStatusModalHapus={setStatusModalHapus} listProduk={listProduk} setListProduk={setListProduk}></Konfirmasi2>

        {statusLoading&&<LoadingItem/>}
         <Container>
        <Stack direction='column' alignItems="center">
          <Typography variant="subtitle1" sx={{m:1}}>Brands</Typography>

          <Box sx={{ width: '100%', mb:20}}>
              <Grid container columnSpacing={{ xs: 3, sm: 3, md: 3 }}>

              {listProduk?.map((produk)=>{
                  return(
                  <>
                  <Grid item xs={6} sm={3} md={2} key={produk.nama}>
                      <Paper sx={{ p:1, m:0.5}}  elevation={3} >
                          <Stack direction="row" justifyContent="space-between"  alignItems="center"  spacing={2}>
                            {/* <Avatar alt={produk.nama} src="/static/images/avatar/1.jpg" /> */}
                              <Avatar alt={titleCase(produk.nama)} src="#" sx={{ width: 24, height: 24 }}/>
                              <Typography variant='subtitle2' sx={{cursor:'pointer', width:"100%"}} onClick={()=>{menujuLink(`/brands/${produk.nama}`)}}>{titleCase(produk.nama)}</Typography>

                              {akunMaster&&<IconButton aria-label="delete" size="small"  onClick={()=>hapusProduk(produk.id,titleCase(produk.nama),listProduk)}>
                                <ClearIcon fontSize="inherit" />
                              </IconButton>}


                          </Stack>
                      </Paper>
                      
                  </Grid>
                  </>

                  )
              })}

              </Grid>

            
              
          </Box>
        </Stack>



        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
        
        <Box components='form' >
        <Stack direction="column">
        
            <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small" >
                <CloseIcon fontSize="inherit"  onClick={handleClose} />
            </IconButton>
            </Stack>

            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{m:"auto"}}>
                Daftar Brand Baru
            </Typography>
            {notifBerhasil&&<Alert severity="info">Pendaftaran Produk {kategori2.nama} Berhasil</Alert>}

            <TextField name='nama' value={kategori2.nama} sx={{mt:2}} label="Nama Brand Baru" variant="outlined" onChange={handleChange}/>

            <Button type="submit" onClick={handleSubmit} sx={{mt:2}} variant="contained">Tambah</Button>
            
        </Stack>    
        </Box> 
                               
        </Stack>
        

      </Modal>


      </Container>


            {akunMaster&&<Fab color="secondary" aria-label="add" sx={{position: 'fixed', bottom: 80,  right: 16,}}>
                <AddIcon  onClick={handleOpen}/>
            </Fab>}
      </>


  )
}
