import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const LoadingItem = () => {
  return (
    <>
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    </>
  )
}
