import { Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { Container, Stack } from '@mui/system'
import React, { useState } from 'react'
import { KolomFormatOrder } from '../components/KolomFormatOrder'




export const FormatOrder = () => {

    const [idKolom, setIdKolom] = useState(0)
    const [jumlahKolom, setJumlahKolom] = useState([])
    

    const generateKolom = () =>{


        setJumlahKolom((prev)=>
            ([...prev,<KolomFormatOrder id={idKolom}/>])
        )
    }



  return (
    <Container>
        <Typography sx={{m:2}}>Fitur sedang dalam pengerjaan...</Typography>
        {/* <Stack sx={{mt:3}}>
            <Typography variant="h5" fontWeight={'bold'} sx={{m:'auto'}}>FORMAT ORDER</Typography>

            <Box component={'form'} >
                <Paper sx={{p:1,mt:2, overflow: 'hidden'}} elevation={3}>
                
                
                {jumlahKolom.map((el,index)=>{
                    return (el)
                })}
                
                <Button variant="outlined" sx={{display:'block',float:'right', m:1}} onClick={generateKolom}>Tambah Kolom</Button>
                
                </Paper>


            </Box>
        </Stack> */}




    </Container>
  )
}
