import { Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'

export const Error = () => {
  return (
    <>
      <Container>
        <Box sx={{mt:5}} textAlign='center'>
          <Typography variant="h5" sx={{m:1}}>Halaman yang anda kunjungi tidak tersedia</Typography>
          <Typography variant="body">Silahkan lakukan Login / Hubungi Admin untuk mendapatkan Akses Halaman</Typography>
        </Box>
        </Container>
    </>
  )
}
