import { Button, Stack, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HalamanIntro = () => {

    const navigate = useNavigate()

  return (
    <>
        <Container>
        <Box sx={{mt:5}} textAlign='center'>
        <Stack >
            <Box>
            <Typography variant="h5" sx={{m:1}}>Selamat Datang di Galaxy App V2</Typography>
            <Typography variant="body">App Versi Terbaru dengan Fitur lebih lengkap dan lebih cepat. Silahkan Login untuk mengakses Akun anda</Typography>
          </Box>

          <Box sx={{m:2}}>
            <Button variant='outlined' onClick={()=>navigate('/login')}>Login</Button>
          </Box>
          </Stack>
        </Box>
        </Container>
    </>
  )
}
