import { Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import {useReactToPrint} from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { titleCase } from "title-case";


export const CetakLabel = () => {

    const componentRef = useRef();
    
    const [formKosong, setFormKosong] = useState()

    const handlePrint = useReactToPrint({
        content : ()=> componentRef.current,
        documentTitle: 'emp-data',
        onAfterPrint: ()=> setPenerima(formKosong)
    })

    const [penerima, setPenerima] = useState({
        nama:'',
        noHp:'',
        ekspedisi:'',
        alamat:'',
        produk:'',
    })

    useEffect(()=>{
        setFormKosong(penerima)
    },[])

    const handleChange = (e) =>{
        setPenerima((prev)=>({
            ...prev,
            [e.target.name]:e.target.value
        }))
    }


  return (
    <>
        <Container sx={{mb:30}}>
            
            <Typography variant='h6' sx={{mt:3, mb:3}} textAlign={'center'}>Cetak Label Galaxy</Typography>
            <Paper elevation={3}>
            <Grid container columns={{ xs: 12, sm: 12, md:12 }} rowSpacing={1} sx={{p:1}}>
                <Grid xs={12} sm={12} md={6} >
                    <Box maxWidth={'600px'} sx={{m:1}}>
                    <TextField name='nama' value={penerima.nama} sx={{mb:1}} id="outlined-basic" label="Penerima" variant="outlined" fullWidth onChange={handleChange} />
                    <Stack direction={'row'} gap={1} >
                    <TextField name='noHp' value={penerima.noHp} sx={{mb:1}} id="noHp" label="Nomor HP" variant="outlined" fullWidth onChange={handleChange}/>
                    <TextField name='ekspedisi' value={penerima.ekspedisi}  onChange={handleChange} sx={{mb:1}} id="ekspedisi" label="Ekspedisi" variant="outlined" fullWidth />
                    </Stack>

                    <TextField
                        name='alamat' value={penerima.alamat}  onChange={handleChange}
                         fullWidth
                        id="outlined-multiline-static"
                        label="Alamat Lengkap Penerima"
                        multiline
                        rows={4}
                        sx={{mb:1}}
                        />

                    <TextField
                        name='produk' value={penerima.produk}  onChange={handleChange}
                         fullWidth
                        id="produk"
                        label="Produk"
                        multiline
                        rows={3}
                        sx={{mb:1}}
                        />
                        </Box>
                </Grid>


                <Grid  xs={12} sm={12} md={6}>
                    <Box sx={{m:1}}>
                    <Box ref={componentRef} sx={{width:330, maxHeight:600, border:'1px solid #bdbdbd', p:2}}>

                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Box
                                component="img"
                                alt="Galaxy Camera"
                                src="https://galaxycamera.id/media/logo/stores/1/logogalaxy1.png"
                                sx={{
                                    height: 55             
                                    }}
                            />
                            <Typography variant="subtitle2">Ekspedisi : {penerima.ekspedisi}</Typography>
                        </Stack>
                        <Divider sx={{mb:1}}></Divider>
                        <Stack>
                            <Typography variant='caption'>Dari : <Box component={'span'} sx={{color:'#c62828', fontWeight:'bold'}}>GALAXYCAMERA.ID</Box></Typography>
                            <Typography  variant='caption'>Ruko Mall Metropolis Townsquare, Blok GM3 No.6</Typography>
                            <Typography variant='caption'>Kelapa Indah, Tangerang, 15118</Typography>
                            <Typography  variant='caption'>Telp : 082111311131</Typography>
                        </Stack>
                        <Divider sx={{mb:1, mt:1}}></Divider>
                        <Stack>
                            <Typography variant='caption' fontWeight={'bold'}>Penerima : <Box component={'span'} sx={{color:'#c62828', fontWeight:'bold'}}>{titleCase(penerima.nama)}</Box></Typography>
                            <Typography variant='caption'  fontWeight={'bold'}>Telp : <Box component={'span'} sx={{ fontWeight:'bold'}}>{penerima.noHp}</Box> </Typography>
                            <Typography variant='caption' fontWeight={'bold'} sx={{maxWidth:350}} style={{whiteSpace: 'pre-wrap'}}>{penerima.alamat}</Typography>
                            
                        </Stack> 

                        <Box sx={{ m:1}}>
                            <Box textAlign={'center'} sx={{border:'1px solid #c62828', color:'#c62828', p:1 }}>
                                    <Stack>
                                    <Typography sx={{lineHeight: 1}} variant="h3" fontWeight={'bold'}>FRAGILE</Typography>
                                    <Typography sx={{lineHeight: 1}} variant='h6' fontWeight={'bold'} >HANDLE WITH CARE</Typography>     
                                    </Stack>
                                </Box>     
                        </Box>

                        <Divider sx={{mb:1, mt:1}}></Divider>
                        <Typography sx={{lineHeight: 1}} variant="subtitle2" textAlign='center'>Terima Kasih Sudah Berbelanja di Galaxycamera.id</Typography>
                        <Divider sx={{mb:1, mt:1}}></Divider>

                        <Stack>
                            <Typography variant='caption' fontWeight={'bold'}>Produk : </Typography>                
                            <Typography variant='caption' fontWeight={'bold'} sx={{maxWidth:350}} style={{whiteSpace: 'pre-wrap'}}>{penerima.produk}</Typography>
                        </Stack>               





                    </Box>
                </Box>
                </Grid>
                
                
            </Grid>


            <Button sx={{m:2, mt:3, width:200}} startIcon={<PrintIcon />} variant="outlined" onClick={handlePrint}>Print</Button>
            </Paper>
        </Container>
    </>
  )
}
