import {  ThemeProvider, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { KolomCari } from './components/KolomCari';
import { HasilPencarian } from './components/HasilPencarian';
import {BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom'
import { Home } from './pages/Home';
import { Profile } from './pages/Profile';
import { TambahProduk } from './pages/TambahProduk';
import { Error } from './pages/Error';
import { Kategori } from './pages/Kategori';
import { Brands } from './pages/Brands';
import { Setting } from './pages/Setting';
import { Brand } from './pages/Brand';
import { KategoriSatuan } from './pages/KategoriSatuan';
import { Login } from './pages/Login';
import { Etalase } from './pages/Etalase';
import { EtalaseSatuan } from './pages/EtalaseSatuan';
import { useAuthState} from "react-firebase-hooks/auth";
import { auth, db } from './config/firebase';
import { createContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { HalamanIntro } from './pages/HalamanIntro';
import { HitungCuan } from './pages/HitungCuan';
import { KartuKredit } from './pages/KartuKredit';
import { Panduan } from './pages/Panduan';
import { CetakLabel } from './pages/CetakLabel';
import { MintaUlasan } from './pages/MintaUlasan';
import { FormatOrder } from './pages/FormatOrder';
import { Masterplan } from './pages/Masterplan';
import { JobDesk } from './pages/JobDesk';
import { About } from './pages/About';
import { TermCondition } from './pages/TermCondition';
import { PanduanCrewStore } from './pages/PanduanCrewStore';
import { PanduanDesainGrafis } from './pages/PanduanDesainGrafis';

export const AppContext = createContext();


function App() {
  const [statusAkun,setStatusAkun] = useState(null)
  const [user, loading,error] = useAuthState(auth);
  const [akunMaster,setAkunMaster] = useState(false)


  const [kategoriProduk,setKategoriProduk] = useState(null)
  const [brandProduk,setBrandProduk] = useState(null)
  const [etalaseProduk,setEtalaseProduk] = useState(null)
  const [produkCari,setProdukCari] = useState(null)

  const cekData = async () =>{
    const docRef = doc(db, "users", user?.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Hello World", docSnap.data());
      const statusAkun = docSnap.data()
      setStatusAkun(statusAkun.status)
      setAkunMaster(statusAkun.admin)

    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setStatusAkun(false)
    }
  }
  
  useEffect(() => {

    console.log('Cek ini terlebih dahulu')

    cekData()

    
  }, [user])
  
  
  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#263238',
      },
      secondary: {
        main: '#c62828',
      },
    },
    typography:{
      fontSize:13,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    }
    
  });




  return (
    <>

<AppContext.Provider value={{statusAkun, akunMaster,kategoriProduk,setKategoriProduk,brandProduk,setBrandProduk,etalaseProduk,setEtalaseProduk,produkCari,setProdukCari}}>
    <ThemeProvider theme={darkTheme}> 
            <CssBaseline />
        
            
              <Router>
                <Header />
                <Routes>

                  {statusAkun?(<>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/profile" element={<Profile/>}/>
                  <Route path="/add-product" element={<TambahProduk/>}/>
                  <Route path="/kategori" element={<Kategori/>}/>
                  <Route path="/kategori/:kategorisatuan" element={<KategoriSatuan/>}/>
                  <Route path="/brands/" element={<Brands/>}/>
                  <Route path="/brands/:brand" element={<Brand/>}/>
                  <Route path="/settings" element={<Setting/>}/>
                  <Route path="/etalase" element={<Etalase/>}/>
                  <Route path="/etalase/:etalasesatuan" element={<EtalaseSatuan/>}/>
                  <Route path="/hitung-cuan" element={<HitungCuan/>}/>
                  <Route path="/kartu-kredit" element={<KartuKredit/>}/>
                  <Route path="/panduan" element={<Panduan/>}/>
                  <Route path="/cetak-label" element={<CetakLabel/>}/>
                  <Route path="/ulasan-google" element={<MintaUlasan/>}/>
                  <Route path="/format-order" element={<FormatOrder/>}/>
                  <Route path="/masterplan" element={<Masterplan/>}/>
                  <Route path="/jobdesk" element={<JobDesk/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/term-condition" element={<TermCondition/>}/>
                  <Route path="/crew-store" element={<PanduanCrewStore/>}/>
                  <Route path="/desain-grafis" element={<PanduanDesainGrafis/>}/>




                  <Route path="/*" element={<Error/>}/>
                  </>):statusAkun == false?
                  <>
                  <Route path="/" element={<Profile/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/*" element={<Error/>}/>
                  </>
                  :<>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/*" element={<HalamanIntro/>}/>
                  </>
                  }
                  
                  
                  
                </Routes>

                <Footer />

              </Router>
            
      </ThemeProvider>
    </AppContext.Provider>
  </>
  );
}

export default App;
