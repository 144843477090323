import { Button, Paper, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React from 'react'
import GoogleIcon from '@mui/icons-material/Google';
import {signInWithRedirect, signInWithPopup} from 'firebase/auth';

import {auth,provider} from '../config/firebase';
import { useNavigate } from 'react-router-dom';



export const Login = () => {


    const navigate = useNavigate()

    const signInWithGoogle = async () =>{
        const result = await signInWithPopup(auth,provider)
        console.log(result)
        navigate('/')

        


    }


    //     const signInWithGoogle =  () =>{
    //     const result = signInWithPopup(auth,provider)
    //     console.log(result)
    //     navigate('/')

        


    // }




  return (
    <>
        <Container>
            <Paper sx={{m:1,mt:10, p:2}} elevation={3}>
                <Stack direction="column" justifyContent={'center'} justifyItems="center">
                <Box sx={{m:'auto',mt:3}}>
                    <Typography sx={{m:'auto', textAlign:'center'}} variant="h6">Login Menggunakan Akun Google Anda</Typography>
                </Box>
                <Box sx={{m:'auto', p:1, mb:2}}>
                    <Button onClick={()=>signInWithGoogle()} variant="contained" color='secondary' endIcon={<GoogleIcon />}>Login Menggunakan Google</Button>
                </Box>
                </Stack>
            </Paper>
        </Container>
    </>
  )
}
