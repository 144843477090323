import { Alert, Button, Chip, FormControl, FormControlLabel, Grid, inputAdornmentClasses, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, Switch, TextField, toggleButtonGroupClasses, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { addDoc,collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useNavigate } from 'react-router-dom';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { titleCase } from "title-case";
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { AppContext } from '../App';

var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)









export const TambahProduk = () => {

  const {akunMaster,kategoriProduk,setKategoriProduk, brandProduk,setBrandProduk,etalaseProduk,setEtalaseProduk} = useContext(AppContext)


    //PRODUK ETALASE
const etalaseRef = collection(db,'etalase')
const daftarEtalase = query(etalaseRef, orderBy("nama", "asc"))
// const [etalase,setEtalase] = useState(null);
const etalase = etalaseProduk;
const setEtalase = setEtalaseProduk;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 700,
    },
  },
};

// const etalase = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

const getEtalase = async () =>{

  const data = await getDocs(daftarEtalase)
   console.log('sedang mencari data',data,daftarEtalase)
   const arraySementara = []
   data.docs.map((doc)=>([
      arraySementara.push(doc.data())

    ]))

    setEtalase(arraySementara)

    

    console.log('ini adalah array sementara ',arraySementara)
  // console.log('selesai mencari data',data,listKategori)
}

function getStyles(name, produkEtalase, theme) {
  return {
    fontWeight:
      produkEtalase.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
  //PRODUK ETALASE



  //PRODUK ETALASE
  const theme = useTheme();
  const [produkEtalase, setProdukEtalase] = React.useState([]);

  const handleMultipleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setProdukEtalase(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  //PRODUK ETALASE


  

  // console.log('Ini merupakan ID Unik ', uuidv4())

  const navigate = useNavigate()

  const postsRef = collection(db,'products')

  const kategoriRef = collection(db,'kategori')
  const daftarkategori = query(kategoriRef, orderBy("nama", "asc"))


  const brandRef = collection(db,'brands')
  const daftarbrand = query(brandRef, orderBy("nama", "asc"))

  // const [listKategori,setListKategori] = useState(null);
  const listKategori = kategoriProduk;
  const setListKategori = setKategoriProduk;


  // const [listBrand,setListBrand] = useState(null);
  const listBrand = brandProduk;
  const setListBrand = setBrandProduk;


  const getKategori = async () =>{
    const data = await getDocs(daftarkategori)
     console.log('sedang mencari data',data,listKategori)
     setListKategori(data.docs.map((doc)=>({
        ...doc.data(), id:doc.id

    })))
    // console.log('selesai mencari data',data,listKategori)
  }

  const getBrand = async () =>{
    const data = await getDocs(daftarbrand)
     console.log('sedang mencari data',data,listKategori)
     setListBrand(data.docs.map((doc)=>({
        ...doc.data(), id:doc.id

    })))
    // console.log('selesai mencari data',data,listBrand)
  }



  useEffect(()=>{
    console.log('Running 1 kali ....')
    if(listKategori === null){
      getKategori()
    }

    if (listBrand===null){
      getBrand()
    }

    if(etalase===null){
      getEtalase()
    }

      
    
    if (listKategori === null && listBrand == null && etalase === null){
        console.log('Loading...')
        // setStatusLoading(true)
    }
 
    // console.log('Ini adalah listProduk ', listKategori, listBrand)

  },[])

  useEffect(()=>{
    // getProduk()
    if (listKategori !== null || listBrand !== null){
        console.log('Loading...')
        // setStatusLoading(false)
    }
 
    //  console.log('Ini adalah listProduk ', listKategori, listBrand)

  },[listKategori,listBrand])


  // const [kategori, setKategori] = useState('');
  // const handleKategori = (event) => {
  //   setKategori(event.target.value);
  //   console.log(event.target.value)
  // };

  


    const [tanggalPeriode, setTanggalPeriode] = useState({
    // mulai:dayjs('2022-09-01'),
    // akhir:dayjs('2014-08-19').format('YYYY-MM-DD')
    mulai:'',
    akhir:''
    })



  const handleTanggalMulai = (newValue) => {
    console.log('Value baru dibawah')
    // console.log(newValue.$M+1)
    setTanggalPeriode((prev)=>({
      ...prev,
      ['mulai']:newValue.format('YYYY-MM-DD')
    }))

  }

  const handleTanggalAkhir = (newValue) => {
    console.log('Value baru dibawah')
    console.log(newValue)
    setTanggalPeriode((prev)=>({
      ...prev,
      ['akhir']:newValue.format('YYYY-MM-DD')
    }))

  }


  const [hargaFinal, setHargaFinal] = useState('')

  const [inputsClear,setInputsClear] = useState('')


  const [inputs,setInputs] = useState({
    produkid:uuidv4(),
    namaProduk : '',
    namaArray:[],
    kategori : '',
    brand : '',
    hargaModal : '',
    hargaNormal : '',
    cashback : '',
    hargaFinal:hargaFinal,
    periodePromo : false,
    periodeMulai:tanggalPeriode.mulai,
    periodeAkhir:tanggalPeriode.akhir,
    garansi:'',
    linkProduk:'',
    free:'',
    freeKlaim:'',
    produkDiscontinue:false,
    keterangan:'',
    lastUpdate:'',
    produkEtalase: []
  })





  useEffect(()=>{
    let hargaAkhir = Number(inputs.hargaNormal)-Number(inputs.cashback)
    setHargaFinal(hargaAkhir)
    setInputs((prev)=>({
      ...prev,
      ['hargaFinal']:hargaAkhir
    }))

  },[inputs.hargaNormal,inputs.cashback])


  const handleChange = (e) =>{
    setInputs((prev)=>({
      ...prev,
      [e.target.name] : e.target.name === "periodePromo" || e.target.name === "produkDiscontinue" ?e.target.checked:e.target.value
    }))
    
    // HASIL CODE SMENTARA
    const arraySementara = [inputs.namaProduk.toLowerCase()]

    const namaPrdk = inputs.namaProduk.toLowerCase()

    namaPrdk.split(' ').forEach((prdk)=>{
      if(prdk.includes("-")){
        console.log('Ada tanda strip terdeteksi')
        arraySementara.push(prdk.toLowerCase())
        arraySementara.push(prdk.replace('-','').toLowerCase())
      }
    })
    const namaPrdk2 = namaPrdk.replace("-", " ");
    const namaPrdk3 = namaPrdk.replace("-","")
    
    
    const hasilSplit = namaPrdk2.split(' ')
    const hasilSplit2 = namaPrdk.split(' ')
    const hasilSplit3 = namaPrdk3.split(' ')
    const hasilSplit4 = namaPrdk2.split(' ')
    // console.log('Ini adalah hasil split',hasilSplit)
    hasilSplit.map((el)=>arraySementara.push(el.toLowerCase()))
    // arraySementara.push(hasilSplit)

    const pecahKataKunci = (kataKunci) => {

      kataKunci.forEach((el,index)=>{
        (
          // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
          kataKunci.forEach((els,ind)=>{
            if (kataKunci[index] === kataKunci[ind]){
              console.log('Disini ada kesamaan suku kata')
            }else{
              (
                arraySementara.push(kataKunci[index].toLowerCase() + ' ' + kataKunci[ind].toLowerCase())
              )
            }
            
          })
        )
      })

    }

    pecahKataKunci(hasilSplit)
    pecahKataKunci(hasilSplit2)
    pecahKataKunci(hasilSplit3)



    hasilSplit4.forEach((el,index)=>{
      if(index === 'undefined'){
        console.log('Disini terdapat undefined', index)
      }
      (
        // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
        hasilSplit4.forEach((els,ind)=>{
          console.log('Type of ind ada lah ', typeof (hasilSplit4[ind+1]), (ind+1), hasilSplit4[ind+1])
          if(hasilSplit4[ind+1] === undefined){
            console.log('Disini terdapat undefined')
          }else{
            (
              arraySementara.push(hasilSplit4[index].toLowerCase() + ' ' + hasilSplit4[ind].toLowerCase() + ' ' + hasilSplit4[ind+1])
            )
          }
         
        })
      )
              console.log('hasil split berkali kali',hasilSplit4[index] + hasilSplit4[index+1])

    })
    
    // HASIL CODE SMENTARA

    // hasilSplit4.reverse().forEach((el,index)=>{
    //   (
    //     // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
    //     hasilSplit4.forEach((els,ind)=>{
    //       (
    //         arraySementara.push(hasilSplit4[index].toLowerCase() + ' ' + hasilSplit4[ind].toLowerCase() + ' ' + hasilSplit4[ind+1])
    //       )
    //     })
    //   )
    //           console.log('hasil reverse berhasil ',hasilSplit4[index] + hasilSplit4[index+1])

    // })

    const arrayTerbaru = [...new Set(arraySementara)]


    setInputs((prev)=>({
      ...prev,
      'namaArray':arrayTerbaru
    }))

    // setHashTag(arrayTerbaru)

    // inputs.namaArray = arrayTerbaru

    // HASIL CODE SMENTARA

    // HASIL CODE SMENTARA


  }


  useEffect(() => {
    
    console.log('Ada perubahan')

    setInputs((prev)=>({
      ...prev,
      periodeMulai:tanggalPeriode.mulai
    }))

    setInputs((prev)=>({
      ...prev,
      periodeAkhir:tanggalPeriode.akhir
    }))

  },[tanggalPeriode]);



  useEffect(() => {
    
    setInputs((prev)=>({
      ...prev,
      produkEtalase:produkEtalase
    }))
  },[produkEtalase]);



  useEffect(()=>{

    const sekarang = dayjs().format('DD-MM-YYYY HH:mm')
    console.log('sekarang ',sekarang)
    setInputs((prev)=>
      ({

        ...prev,
        lastUpdate:sekarang
      })
    )
    

    setInputsClear(inputs)


  },[])


  // useEffect(()=>{
  //   const arraySementara = [inputs.namaProduk]
  //   const hasilSplit = inputs.namaProduk.split(' ')
  //   // console.log('Ini adalah hasil split',hasilSplit)
  //   hasilSplit.map((el)=>arraySementara.push(el))
  //   // arraySementara.push(hasilSplit)
  //   console.log('Panjang hasil Split adalah ' ,hasilSplit.length)

  //   hasilSplit.forEach((el,index)=>{
  //     (
  //       // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
  //       hasilSplit.forEach((els,ind)=>{
  //         (
  //           arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[ind])
  //         )
  //       })
  //     )

  //             console.log('hasil split berkali kali',hasilSplit[index] + hasilSplit[index+1])

  //   })


  //   setInputs((prev)=>({
  //     ...prev,
  //     namaArray:arraySementara
  //   }))


  //   console.log('Ini adalah array sementara', inputs.namaArray)

  // },[inputs.namaProduk])







  const handleSubmit = async (e) =>{
    e.preventDefault()
    





    
    console.log('Berhasil handle Submit')
    console.log('Ini merupakan inputs terbaru ', isNaN(inputs.periodeMulai.$D), inputs.periodeMulai.$D)
    console.log('Ini merupakan inputs terbaru 2',inputs)

   

    inputs.namaProduk = inputs.namaProduk.toLowerCase().trim()
    inputs.kategori = inputs.kategori.toLowerCase()
    inputs.brand = inputs.brand.toLowerCase()
    inputs.linkProduk = inputs.linkProduk.trim()


    if (inputs.periodePromo && inputs.periodeMulai.length === 0 && inputs.periodeAkhir.length === 0 ){

      alert('Mohon isi tanggal periode mulai dan akhir',inputs.periodePromo)
    }
    else{

      const sekarang = dayjs().format('DD-MM-YYYY HH:mm')
      console.log('sekarang ',sekarang)
      setInputs((prev)=>
        ({
  
          ...prev,
          lastUpdate:sekarang
        })
      )

      const docRef = await addDoc(postsRef,{
        ...inputs
      });
      navigate('/add-product')
      alert('Pendaftaran Produk berhasil')
      setInputs(inputsClear)
      setProdukEtalase([])
      setTanggalPeriode(()=>({
        ['mulai']:'',
        ['akhir']:''
      }))
    }

  }


  const tambahInfoGaransi = (info) =>{
    setInputs((prev)=>
      ({
        ...prev,
        garansi:info
      })
    )
  }


  return (
    <>
      <Container>

        <Paper elevation={3} sx={{mb:20}}>
        <Stack justifyContent="center"  sx={{m:1, p:1, mt:5}}>
        <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
              <Typography variant="subtitle"  justifyContent='center' sx={{m:"auto"}}>Daftar Produk Baru</Typography>
              <TextField required name='namaProduk'  label="Nama Produk" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.namaProduk} onChange={handleChange}/>

            
              <FormControl fullWidth sx={{mt:1}} required>
              <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
              <Select
                name='kategori'
                labelId="demo-simple-select-label"
                label="Kategori"
                value={inputs.kategori}
                onChange={handleChange}
                
              >
                {listKategori?.map((kategori)=>{
                  return(
                    <MenuItem key={kategori.nama} value={kategori.nama}>{titleCase(kategori.nama)}</MenuItem>
                  )
                })}
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
              </FormControl>

              <FormControl fullWidth sx={{mt:1}} required>
              <InputLabel id="demo-simple-select-label">Brand</InputLabel>
              <Select
                name='brand'
                labelId="demo-simple-select-label"
                label="Brand"
                value={inputs.brand}
                onChange={handleChange}
                required
              >
              {listBrand?.map((brand)=>{
                  return(
                    <MenuItem key={brand.nama} value={brand.nama}>{titleCase(brand.nama)}</MenuItem>
                  )
                })}
                {/* <MenuItem value={'Canon'}>Canon</MenuItem>
                <MenuItem value={20}>Insta360</MenuItem>
                <MenuItem value={30}>Panasonic</MenuItem> */}
              </Select>
              </FormControl>

              <TextField required type="number" name="hargaNormal" label="Harga Jual" variant="outlined" sx={{width:"100%", m:'auto', mt:2}}  value={inputs.hargaNormal} onChange={handleChange} />

              <TextField onClick={()=>{}}type="number" name="hargaModal" label="Harga Modal" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.hargaModal} onChange={handleChange} />

              
              <Stack direction="row" justifyContent="space-between"  alignItems="center" justifyItems={'center'} sx={{mt:1}}>
                <TextField  type="number" name="cashback" label="CashBack" variant="outlined" sx={{width:"33%",m:'auto'}} value={inputs.cashback} onChange={handleChange}/>
                <Box sx={{p:1, m:1, borderRadius:2, width:"33%", textAlign:'center'}} >
                    <Stack direction="column" >
                    <Typography variant="body2">Harga Modal :</Typography>
                    <Typography variant="body1" sx={{fontWeight:'bold'}}>{Number(inputs.hargaModal).toLocaleString()}</Typography>
                    </Stack>
                  </Box>
                  <Box sx={{p:1, m:1, borderRadius:2, width:"33%", textAlign:'center'}} >
                    <Stack direction="column" >
                    <Typography variant="body2" >Harga Final :</Typography>
                    <Typography variant="body1" sx={{fontWeight:'bold', color:'#c62828'}}> {hargaFinal.toLocaleString()}</Typography>
                    </Stack>
                  </Box>
              </Stack>

              <FormControlLabel  sx={{width:"100%", m:'auto'}} control={<Switch checked={inputs.periodePromo} name='periodePromo' value={inputs.periodePromo} onChange={handleChange} /> } label="Terdapat Periode Promo ?" />

              {/* TANGGAL */}
             {inputs.periodePromo&&<Stack direction="row" alignItems="center" justifyContent="space-between" >

              
                
                <LocalizationProvider dateAdapter={AdapterDayjs} gap={2}>
                  <Stack spacing={3} sx={{width:'49%', mt:2}}>
                    <MobileDatePicker
                      
                      name='periodeMulai'
                      label="Periode Mulai"
                      inputFormat="DD/MM/YYYY"
                      value={tanggalPeriode.mulai}
                      onChange={handleTanggalMulai}
                      renderInput={(params) => <TextField {...params} />}
                      // disabled={!inputs.periodePromo}
                    />
                  </Stack>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{width:'49%', mt:2}}>
                    <MobileDatePicker
                      name='periodeAkhir'
                      label="Periode Akhir"
                      inputFormat="DD/MM/YYYY"
                      value={tanggalPeriode.akhir}
                      onChange={handleTanggalAkhir}
                      renderInput={(params) => <TextField {...params} />}
                      // disabled={!inputs.periodePromo}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>}

              <Stack direction='row' gap={1} sx={{mb:1, mt:1, flexWrap: 'wrap'}} justifyContent="flex-start">
                <Chip size="small"  icon={<AddCircleIcon />} label="Resmi 1 Tahun" variant="outlined"  onClick={()=>tambahInfoGaransi("Resmi 1 Tahun")} />
                <Chip  size="small" icon={<AddCircleIcon />} label="Resmi 2 Tahun" variant="outlined"  onClick={()=>tambahInfoGaransi("Resmi 2 Tahun")} />
                <Chip  size="small" icon={<AddCircleIcon />} label="Resmi 18 Bulan" variant="outlined"  onClick={()=>tambahInfoGaransi("Resmi 18 Bulan")} />
                <Chip size="small"  icon={<AddCircleIcon />} label="1 Tahun" variant="outlined"  onClick={()=>tambahInfoGaransi("1 Tahun")} />
                <Chip  size="small" icon={<AddCircleIcon />} label="2 Tahun" variant="outlined"  onClick={()=>tambahInfoGaransi("2 Tahun")} />
              </Stack>
              <TextField name="garansi" label="Garansi" variant="outlined" sx={{width:"100%", m:'auto', mt:1}} value={inputs.garansi} onChange={handleChange}/>
              <TextField name="linkProduk" label="Link Produk" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.linkProduk} onChange={handleChange}/>
              <TextField name="free" label="Free" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.free} onChange={handleChange}/>
              <TextField name="freeKlaim" label="Free Klaim" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.freeKlaim} onChange={handleChange}/>

              {inputs.produkDiscontinue&&<Alert severity="info" sx={{mt:1}}>Status Produk Saat Ini : Discontinue</Alert>}

              <FormControlLabel  sx={{width:"100%", m:'auto', mt:1}} control={<Switch checked={inputs.produkDiscontinue} name='produkDiscontinue' value={inputs.produkDiscontinue} onChange={handleChange} /> } label="Produk Sudah Discontinue ?" />
              


              <TextField name="keterangan" label="Keterangan" multiline rows={4}  variant="standard" sx={{width:"100%", m:'auto'}} value={inputs.keterangan} onChange={handleChange}/>

              {/* defaultValue="Default Value" BERGUNA UNTUK MASUKKAN DEFAULT VALUE */}


              {produkEtalase?.map((satuan)=>(
                <>
                {console.log(satuan)}
                <Chip key={satuan} label={titleCase(satuan)} sx={{mt:1.5, mr:1}}/>
                </>
              ))}

              <FormControl sx={{mt:1.5,width: '100%' }}>
                <InputLabel id="demo-multiple-name-label">Etalase Produk</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={produkEtalase}
                  onChange={handleMultipleSelect}
                  input={<OutlinedInput label="Etalase Produk" />}
                  MenuProps={MenuProps}
                >
                  {etalase?.map((name) => (
                    
                    <MenuItem
                      key={name.nama}
                      value={name.nama}
                      style={getStyles(name.nama, produkEtalase, theme)}
                    >
                      {titleCase(name.nama)}
                    </MenuItem>
                  ))}
                </Select>
      </FormControl>


              

              
              <Stack direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mt:2, mb:2}}>
                    {akunMaster&&<Button sx={{width:'100%', height:40}}variant="contained" endIcon={<AddIcon />} type='submit'>Daftar</Button>}
              </Stack>
            </Box>
        </Stack>
        </Paper>


      </Container>

     
    </>
  )
}
