import { Container, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

export const About = () => {
  return (
    <>
        <Container>
        <Stack sx={{mt:3, mb:20}}>
        
        <Stack direction={'row'} sx={{m:'auto', mb:2}} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} variant="h5" >TENTANG GALAXY</Typography>
        </Stack>

        <Typography variant='body2'>Galaxy Camera pertama kali didirikan pada tanggal 25 Maret 2014.</Typography>
        
        <Stack sx={{mt:2}}>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Nama Perusahaan :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>PT Galaxy Digital Niaga</Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Nama Toko :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy Camera</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Website Resmi :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxycamera.id</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Website Resmi :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy.co.id</Typography>
            </Stack>

            <Divider sx={{m:2}}></Divider>
            <Typography variant='body2' fontWeight={'bold'}>Kontak : </Typography>


            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>CS / Admin Penjualan :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>0821-1131-1131</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>CS /  Admin Purchasing :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>0821-2233-5511</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Email :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>sales@galaxycamera.id</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Email :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>galaxycameraid@gmail.com</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Email :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>sales@galaxy.co.id</Typography>
            </Stack>



            <Divider sx={{m:2}}></Divider>
            <Typography variant='body2' fontWeight={'bold'}>Alamat : </Typography>


            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Tangerang :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>Ruko Mall Metropolis Townsquare, Blok GM3 No.6, Kelapa Indah, Tangerang, 15118</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Depok :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>Mall Depok Town Square, Lantai 2 Blok SS2 No.8, Beji, Depok 16424</Typography>
            </Stack>



            <Divider sx={{m:2}}></Divider>
            <Typography variant='body2' fontWeight={'bold'}>Bank: </Typography>


            <Stack direction={'column'} alignItems={'left'} sx={{mb:1, mt:1}}>
                <Typography variant='body2'>BCA</Typography>
                <Typography variant='body2' fontWeight={'bold'}>No Rek : 8680767808 </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Atas Nama : PT. Galaxy Digital Niaga </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Cabang : KCU Tangerang City </Typography>
            </Stack>

            <Stack direction={'column'} alignItems={'left'} sx={{mb:1}}>
                <Typography variant='body2'>BCA</Typography>
                <Typography variant='body2' fontWeight={'bold'}>No Rek : 0352865646 </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Atas Nama : Noreaga </Typography>
            </Stack>

            <Stack direction={'column'} alignItems={'left'} sx={{mb:1}}>
                <Typography variant='body2'>BRI</Typography>
                <Typography variant='body2' fontWeight={'bold'}>No Rek : 035901001168569 </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Atas Nama : PT. Galaxy Digital Niaga </Typography>
            </Stack>

            <Typography variant='body2'>Jika transfer <Typography variant='body2' style={{display: 'inline-block', color:'#f50057'}}>diatas jam 6 sore</Typography> harap diarahkan ke rekening BCA Noreaga, dikarenakan jam operasional Admin hanya sampai jam 6 sore untuk pengecekan transferan masuk. </Typography>












            <Divider sx={{m:2}}></Divider>
            <Typography variant='body2' fontWeight={'bold'}>Sosial Media : </Typography>


            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Instagram :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>@Galaxycamera99</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Facebook :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>https://facebook.com/galaxycamera99</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Youtube :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>https://youtube.com/galaxycamera</Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Typography variant='body2'>Tiktok :</Typography>
                <Typography variant='body2' fontWeight={'bold'}>https://www.tiktok.com/@galaxycameraid</Typography>
            </Stack>


            <Divider sx={{m:2}}></Divider>
            <Typography variant='body2' fontWeight={'bold'} sx={{mb:1}}>Marketplace : </Typography>
            
            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Tokopedia : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy Camera - https://tokopedia.com/galaxycamera</Typography>
            </Stack>

            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Tokopedia Depok : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy Camera Depok Detos - https://www.tokopedia.com/galaxycameraid1</Typography>
            </Stack>

            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Shopee : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy Camera Official - https://shopee.co.id/galaxycamera</Typography>
            </Stack>

            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Blibli : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxy Camera Official Store - https://www.blibli.com/merchant/galaxy-camera-official-store/GAC-49845</Typography>
            </Stack>

            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Lazada : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Galaxycamera99 - https://www.lazada.co.id/shop/galaxycamera99/</Typography>
            </Stack>

            <Stack direction={'column'} sx={{mb:1}}>
                <Typography variant='body2'>Akulaku : </Typography>
                <Typography variant='body2' fontWeight={'bold'}>Tidak bisa di search, harus by produk di App Akulaku</Typography>
            </Stack>

            


        </Stack>





        </Stack>
        </Container>
    </>
  )
}
