import { Container } from '@mui/system'
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Box, Divider, Typography } from '@mui/material';



function createData(namaKartu, fullpayment,t3bulan, t6bulan, t12bulan) {
    return { namaKartu, fullpayment,t3bulan, t6bulan, t12bulan};
  }
  
  const rows = [
    createData('BCA Card','1.5%' ,'3%' , '6%', '9%'),
    createData('Visa, Mastercard, JCB','2%' ,'3%' , '6%', '9%'),
    createData('Amex','2.5%' ,'3.25%' , '6%', '9%'),








  ];

  
export const KartuKredit = () =>{
    return (
    <Container sx={{mb:50 }} >
        <Typography variant='h6' sx={{m:'auto', mt:3}} textAlign='center'>Info Bunga dan Biaya Admin Kartu Kredit Mesin EDC</Typography>
        <Typography variant='body2' sx={{m:'auto'}}  textAlign='center'>Berikut merupakan biaya layanan yang dibebankan kepada customer, untuk setiap transaksi di Mesin EDC.</Typography>
      
      <Typography variant="h6" sx={{mt:3, mb:1}}>Biaya Layanan EDC BCA - Cicilan 0% & Full Payment</Typography>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Jenis Kartu Kredit</TableCell>
              <TableCell align="right">Full Payment (30 Hari)</TableCell>
              <TableCell align="right">3 Bulan</TableCell>
              <TableCell align="right">6 Bulan</TableCell>
              <TableCell align="right">12 Bulan</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.namaKartu}
                </TableCell>
                <TableCell align="right">{row.fullpayment}</TableCell>
                <TableCell align="right">{row.t3bulan}</TableCell>
                <TableCell align="right">{row.t6bulan}</TableCell>
                <TableCell align="right">{row.t12bulan}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    <Box sx={{mt:2}}>
        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>CONTOH : Menggunakan Kartu Kredit Visa/Mastercard/JCB </Typography>
        <Typography variant="body2">Tripod Beike Q999H seharga Rp 1.000.000</Typography>
        <Typography variant="body2">Biaya Layanan Full Payment 30 Hari (2%) = 20.000 menjadi 1.020.000</Typography>
        <Typography variant="body2">Biaya Layanan 3 Bulan (3%) = 30.000 menjadi 1.030.000</Typography>
        <Typography variant="body2">Biaya Layanan 6 Bulan (6%) = 60.000 menjadi 1.060.000</Typography>
        <Typography variant="body2">Biaya Layanan 12 Bulan (9%) = 90.000 menjadi 1.090.000</Typography>

        <Box  sx={{mt:1, color:'#01579b', backgroundColor:'#e1f5fe', p:2, borderRadius:2}}>
            <Typography  variant="body2" fontWeight='bold'>PROMO : </Typography>
            <Typography variant='body2' >- Khusus cicilan 3bln, 6bln dan 12bln pembelian dengan harga <Box fontWeight='bold' display='inline'>SRP</Box>, customer bisa mendapatkan <Box fontWeight='bold' display='inline'>DISKON BIAYA LAYANAN SEBESAR 3%</Box>, Tidak berlaku untuk customer yang sudah mendapatkan Best Price dari Admin Galaxy. </Typography>
            <Typography variant='body2' >- Khusus transaksi kartu kredit Full Payment(30 hari), customer bisa mendapatkan <Box fontWeight='bold' display='inline'>FREE BIAYA LAYANAN</Box>, Tidak berlaku untuk customer yang sudah mendapatkan Best Price dari Admin Galaxy. </Typography>
        </Box>

        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Ketentuan </Typography>
        <Typography variant='body2' >- Tidak berlaku untuk produk GNP</Typography>

        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Pertanyaan Umum : </Typography>
        <Typography variant='body2' >1. Kartu Kredit apa saja yang bisa di gesek di mesin EDC BCA ini ?</Typography>
        <Typography variant='body2' sx={{ color:'grey'}}>Jawab : Semua Jenis Kartu Kredit Bank yang ada di Indonesia (BCA Card, BCA, BRI, BNI, CIMB, Danamon, Mandiri dan lain-lain) / Luar Negeri, selama kartu kredit tersebut memiliki logo Visa/Mastercard/Amex/Jcb. </Typography>

        <Typography variant='body2' sx={{mt:1}} >2. Berapa minimal harga produk untuk transaksi kartu kredit di mesin EDC BCA ?</Typography>
        <Typography variant='body2' sx={{ color:'grey'}}>Jawab : Untuk cicilan 3bln, 6bln & 12bln minimal transaksi 500rb, sedangkan untuk Full Payment(30 hari) minimal 50rb. </Typography>




    </Box>

    <Divider sx={{mt:3, mb:3}}/>



    <Typography variant="h6" sx={{mt:1, mb:1}}>Biaya Layanan EDC BNI - Full Payment 30 Hari</Typography>
      <TableContainer component={Paper} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Jenis Kartu Kredit</TableCell>
              <TableCell align="right">Full Payment (30 Hari)</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            
              <TableRow
                key='Visa Mastercard'
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  Visa & Mastercard
                </TableCell>
                <TableCell align="right">2.5%</TableCell>
  

              </TableRow>
    
          </TableBody>
        </Table>
      </TableContainer>

    

    
      <Box sx={{mt:2}}>
        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Contoh : </Typography>
        <Typography variant="body2">Tripod Beike Q999H seharga Rp 1.000.000</Typography>
        <Typography variant="body2">Biaya Layanan (2.5%) = 25.000</Typography>
 
        <Box  sx={{mt:1, color:'#01579b', backgroundColor:'#e1f5fe', p:2, borderRadius:2}}>
            <Typography  variant="body2" fontWeight='bold'>PROMO : </Typography>
            <Typography variant='body2' >- <Box fontWeight='bold' display='inline'>FREE BIAYA LAYANAN</Box> khusus untuk transaksi dengan harga SRP, Tidak berlaku untuk customer yang sudah mendapatkan Best Price dari Admin Galaxy. </Typography>
        </Box>

        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Ketentuan : </Typography>
        <Typography variant='body2' >- Berlaku untuk semua produk Galaxy, termasuk GNP</Typography>

        <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Pertanyaan Umum : </Typography>
        <Typography variant='body2' >1. Kartu Kredit apa saja yang bisa di gesek di mesin EDC BNI ini ?</Typography>
        <Typography variant='body2' sx={{ color:'grey'}}>Jawab : Semua Jenis Kartu Kredit Bank yang ada di Indonesia (BCA, BRI, BNI, CIMB, Danamon, Mandiri dan lain-lain) / Luar Negeri, selama kartu kredit tersebut memiliki logo Visa/Mastercard/Amex/Jcb. Khusus BCA Card tidak bisa. </Typography>

        <Typography variant='body2' sx={{mt:1}} >2. Kartu Kredit apa saja yang bisa di gesek di mesin EDC BNI ini ?</Typography>
        <Typography variant='body2' sx={{ color:'grey'}}>Jawab : Minimal 50rb.</Typography>

    </Box>

    <Divider sx={{mt:3, mb:3}}/>


    <Typography variant="h6" sx={{mt:1, mb:1}} id="blibli">Blibli Instore</Typography>

    <Box
      component="img"
      alt="list bank kartu kredit"
      src="https://galaxycamera.id/media/wysiwyg/blibli.jpg"
      sx={{height:800, m:'auto', display:'flex',
      maxHeight:{xs:500, md:800}
      }}
      
    /> 
      <Typography variant='caption' sx={{ color:'grey'}}>List Kartu Kredit yang bisa transaksi di Blibli Instore</Typography>
      <Typography sx={{mt:1}} variant="body2" fontWeight='bold'>Ketentuan : </Typography>
      <Typography variant='body2' >- Pastikan kartu kredit berlogo Visa/Mastercard.</Typography>
      <Typography variant='body2' >- Jangan lupa membawa Nomor HP yang terdaftar di kartu kredit tersebut untuk mendapatkan sms kode OTP.</Typography>

      





      </Container>
    );
  }
