import { Box, IconButton, Modal, Paper, Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import React from 'react'
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert, Avatar, Button, Divider, Fab, Grid, Menu, MenuItem, TextField } from '@mui/material'
import  { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { useNavigate,useParams } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import dayjs from 'dayjs';
import { LoadingItem } from '../components/LoadingItem';
import { Konfirmasi } from '../components/Konfirmasi';
import ClearIcon from '@mui/icons-material/Clear';
import { Konfirmasi2 } from '../components/Konfirmasi2';
import { titleCase } from "title-case";
import { useContext } from 'react';
import { AppContext } from '../App';


var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime)


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:5,
  };


export const Etalase = () => {
  
  const {etalaseProduk,setEtalaseProduk} = useContext(AppContext)


    const [statusLoading, setStatusLoading] = useState(false)

    const postsRef1 = collection(db,'etalase')
    const postsRef = query(postsRef1, orderBy("nama", "asc"))


    // const [listProduk,setListProduk] = useState(null);
    const listProduk = etalaseProduk;
    const setListProduk = setEtalaseProduk;


    const [postTerakhir,setPostTerakhir] = useState();
    const [notifBerhasil,setNotifBerhasil] = useState(false)

    

    // const getProduk = async () =>{
    //   const data = await getDocs(postsRef)
    //    console.log('sedang mencari data',data,listProduk)
    //     setListProduk(data.docs.map((doc)=>({
    //       ...doc.data(), id:doc.id
  
    //   })))
    //   console.log('selesai mencari data',data,listProduk)
    // }


    //Metode Baru
    useEffect(()=>{
      if (listProduk === null){
          console.log('Loading...')
          setStatusLoading(true)

          const q = query(postsRef)
          const unsub = onSnapshot(q,(QuerySnapshot)=>{
        let arraySementara = [];
        
        QuerySnapshot.forEach((doc)=>{
        arraySementara.push({...doc.data(),id:doc.id});
      });
      const dataTerakhir = QuerySnapshot.docs[QuerySnapshot.docs.length -1];
      console.log("Data Terakhir adalah ",dataTerakhir)
      setPostTerakhir(dataTerakhir)
      setListProduk(arraySementara);
    
    })

      }

      
    
    },[]);
  
  


    let navigate = useNavigate()

    const menujuLink = (link) =>{
        navigate(link)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl(null);
    };


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [kategori2,setKategori] = useState({
        nama:'',
        id:''
    })

    const handleChange = (e) =>{
        console.log(e.target.value)
        setKategori((prev)=>({
            id:dayjs().format('YYYYMMDDmmssSSS'),
            [e.target.name]:e.target.value
        }))

        console.log(kategori2)
    }

    const handleSubmit = async (e) =>{
         e.preventDefault()
        
        console.log('Berhasil')
        kategori2.nama = kategori2.nama.toLowerCase()

    
          await addDoc(postsRef1,{
            ...kategori2
          });

          setNotifBerhasil(true)
          
          setTimeout(() => {
            setNotifBerhasil(false);
          }, 1000);

          setKategori((prev)=>({
            id:dayjs().format('YYYYMMDDmmssSSS'),
            ['nama']:''
        }))
       

        }


        useEffect(()=>{
            // getProduk()
            if (listProduk === null){
                console.log('Loading...')
                setStatusLoading(true)
            }
         
            console.log('Ini adalah listProduk ', listProduk)
        
          },[])
        
          useEffect(()=>{
            // getProduk()
            if (listProduk !== null){
                console.log('Loading...')
                setStatusLoading(false)
            }
         
             console.log('Ini adalah listProduk ', listProduk)
        
          },[listProduk])

                  // Berfungsi untuk konfirmasi hapus produk
    const [statusModalHapus,setStatusModalHapus] = useState({
        idProduk:null,
        namaProduk:null,
        postRef:null,
        statusModal:false,
    })



    const hapusProduk = async (produk,nama,listProduk) =>{
      const idHapus = produk
      console.log('Nama Produk',nama)
      const postRef = doc(db,"etalase",idHapus);
      const q = query(collection(db, "products"), where("produkEtalase", "array-contains", nama.toLowerCase()));
      const querySnapshot = await getDocs(q)
      console.log('Panjang dokumen ', querySnapshot.docs.length)
      const panjangDokumen = querySnapshot.docs.length
      if(panjangDokumen === 0){
          setStatusModalHapus({
            idProduk:produk,
            namaProduk:nama,
            postRef:postRef,
            statusModal:true,
          })
      }else{
        alert('Etalase tidak dapat dihapus karena terdapat produk terdaftar')
      }


    }









  return (
    <>
      <Konfirmasi2 statusModalHapus={statusModalHapus} setStatusModalHapus={setStatusModalHapus} listProduk={listProduk} setListProduk={setListProduk}></Konfirmasi2>

        {statusLoading&&<LoadingItem/>}
        <Container>
            <Typography variant="subtitle1" sx={{textAlign:'center', m:1}}>Etalase Produk</Typography>
            <Stack>

            {listProduk?.map((produk)=>{
            return (
                <Paper elevation={3} sx={{cursor:'pointer', width:"100%", mt:1, p:1}} >
                <Stack direction='row' gap={1} alignItems='center' justifyContent="space-between">
                    <Stack  direction='row' gap={1} alignItems='center' sx={{width:'100%'}} onClick={()=>{menujuLink(`/etalase/${produk.nama}`)}}>
                        <ListAltIcon/>
                        <Typography variant='body2'>{titleCase(produk.nama)}</Typography>
                    </Stack>
               

                     <IconButton aria-label="delete" size="small"  onClick={()=>hapusProduk(produk.id,titleCase(produk.nama),listProduk)}>
                        <ClearIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
                </Paper>
            )})}
            


            
            </Stack>

            
        </Container>


        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
        
        <Box components='form' >
        <Stack direction="column">
        
            <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small" onClick={handleClose} >
                <CloseIcon fontSize="inherit" />
            </IconButton>
            </Stack>

            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{m:"auto"}}>
                Daftar Etalase Baru
            </Typography>
            {notifBerhasil&&<Alert severity="info">Pendaftaran Produk {kategori2.nama} Berhasil</Alert>}

            <TextField name='nama' value={kategori2.nama} sx={{mt:2}} label="Nama Brand Baru" variant="outlined" onChange={handleChange}/>

            <Button type="submit" onClick={handleSubmit} sx={{mt:2}} variant="contained">Tambah</Button>
            
        </Stack>    
        </Box> 
                               
        </Stack>
        

      </Modal>

      <Fab color="secondary" aria-label="add" sx={{position: 'fixed', bottom: 80,  right: 16,}}>
                <AddIcon  onClick={handleOpen}/>
            </Fab>

            
    </>
  )
}
