import { Box, Container, Fab, Paper, Typography } from '@mui/material'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import { HasilPencarian } from '../components/HasilPencarian';
import { db } from '../config/firebase';
import { titleCase } from "title-case";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';

export const EtalaseSatuan = () => {

  const [listProduk,setListProduk] = useState(null);
  const [textSiapCopy, setTextSiapCopy] = useState('')

  let {etalasesatuan} = useParams();
  console.log(etalasesatuan)
  
  const q = query(collection(db, "products"), where("produkEtalase", "array-contains", etalasesatuan));
  
  const loadData = async () =>{
    const querySnapshot = await getDocs(q);
    console.log('sedang mencari data',querySnapshot,listProduk)
  setListProduk(querySnapshot.docs.map((doc)=>({
    ...doc.data(), id:doc.id

})))
console.log('selesai mencari data',querySnapshot,listProduk)

  }
  
  
  useEffect(()=>{
    console.log('Sedang coba ....')
    loadData()
  },[])


  useEffect(()=>{
    if (listProduk){
      const textBaru = listProduk.map((text)=>{
        return (titleCase(text.namaProduk) + '\n' + 'Harga Rp ' + Number(text.hargaFinal).toLocaleString() + '\n' + 'Link Produk :' + text.linkProduk)
      })
      console.log('Text baru ', textBaru)
      const textModified = textBaru.join('\n\n')
      setTextSiapCopy(textModified)
      
    }

  },[listProduk])

  // let hargaCash = `${titleCase(text.namaProduk)}
  // ${text.hargaNormal&&`Harga : ${newHargaNormal.toLocaleString()}`}${text.cashback&&`\nCashback : ${newCashback.toLocaleString()}
  // Harga Spesial : ${text.hargaFinal.toLocaleString()}`}${text.garansi&&`\nGaransi : ${text.garansi}`}${text.free&&`\nFree : ${text.free}`}${text.freeKlaim&&`\nFree Klaim : ${text.freeKlaim}`}${text.periodePromo?`\nPeriode Promo : ${dayjs(text.periodeMulai).format('DD-MM-YYYY')} s/d ${dayjs(text.periodeAkhir).format('DD-MM-YYYY')}`:''}${text.linkProduk&&`\nInfo Produk : ${text.linkProduk}`}${text.keterangan&&`\nKeterangan : ${text.keterangan}`}
  // `
  


  const onCopyText = () =>{
    console.log('Berhasil')
  }

  return (
    <>
        <Container>
                <Box sx={{m:2}}>
                    <Typography>Produk dengan Etalase {titleCase(etalasesatuan)}</Typography>
                </Box>


            <Box sx={{mb:10,mt:2}}>
              {listProduk?.map((produk)=>{
                return (
                  <HasilPencarian 
                    produk={produk}/>)
              })}

            </Box>

        </Container>


        
        
        
         
        {listProduk&&<Fab color="secondary" aria-label="add" sx={{position: 'fixed', bottom: 80,  right: 16,}}>
          <CopyToClipboard  text={textSiapCopy} onCopy={()=>onCopyText()}>
                  <ContentCopyIcon />
            </CopyToClipboard>
          </Fab>}
          
    </>
  )
}
