import { Alert, Button, Chip, Dialog, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { collection, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { titleCase } from "title-case";
import { useTheme } from '@emotion/react';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius:5,

  };
  

export const Edit = (props) => {

      //PRODUK ETALASE
const etalaseRef = collection(db,'etalase')
const daftarEtalase = query(etalaseRef, orderBy("nama", "asc"))
const [etalase,setEtalase] = useState(null);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 700,
    },
  },
};

// const etalase = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

const getEtalase = async () =>{
  const data = await getDocs(daftarEtalase)
   console.log('sedang mencari data',data,daftarEtalase)
   const arraySementara = []
   data.docs.map((doc)=>([
      arraySementara.push(doc.data())

    ]))

    setEtalase(arraySementara)

    

    console.log('ini adalah array sementara ',arraySementara)
  // console.log('selesai mencari data',data,listKategori)
}

function getStyles(name, produkEtalase, theme) {
  return {
    fontWeight:
      produkEtalase.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
  //PRODUK ETALASE



  //PRODUK ETALASE
  const theme = useTheme();

  const [produkEtalase, setProdukEtalase] = React.useState(props.produk.produkEtalase);

  const handleMultipleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setProdukEtalase(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  //PRODUK ETALASE





  const kategoriRef = collection(db,'kategori')
  const daftarkategori = query(kategoriRef, orderBy("nama", "asc"))


  const brandRef = collection(db,'brands')
  const daftarbrand = query(brandRef, orderBy("nama", "asc"))

  const [listKategori,setListKategori] = useState(null);
  const [listBrand,setListBrand] = useState(null);


  const getKategori = async () =>{
    const data = await getDocs(daftarkategori)
     console.log('sedang mencari data',data,listKategori)
     setListKategori(data.docs.map((doc)=>({
        ...doc.data(), id:doc.id

    })))
    console.log('selesai mencari data',data,listKategori)
  }

  const getBrand = async () =>{
    const data = await getDocs(daftarbrand)
     console.log('sedang mencari data',data,listKategori)
     setListBrand(data.docs.map((doc)=>({
        ...doc.data(), id:doc.id

    })))
    console.log('selesai mencari data',data,listBrand)
  }

  

  const [tanggalPeriode, setTanggalPeriode] = useState({
    // mulai:dayjs('2022-09-01'),
    // akhir:dayjs('2014-08-19').format('YYYY-MM-DD')
    mulai:props.produk.periodeMulai,
    akhir:props.produk.periodeAkhir
    })

  const [hargaFinal, setHargaFinal] = useState('')

  const [hashTag,setHashTag] = useState(props.produk.namaArray)

  const [inputs,setInputs] = useState({
    id:props.produk.id,
    namaProduk : titleCase(props.produk.namaProduk),
    namaArray:hashTag,
    kategori :titleCase(props.produk.kategori),
    brand : titleCase(props.produk.brand),
    hargaModal : props.produk.hargaModal,
    hargaNormal : props.produk.hargaNormal,
    cashback : props.produk.cashback,
    hargaFinal:hargaFinal,
    periodePromo :props.produk.periodePromo,
    periodeMulai:tanggalPeriode.mulai,
    periodeAkhir:tanggalPeriode.akhir,
    garansi:props.produk.garansi,
    linkProduk:props.produk.linkProduk,
    free:props.produk.free,
    freeKlaim:props.produk.freeKlaim,
    produkDiscontinue:props.produk.produkDiscontinue,
    keterangan:props.produk.keterangan,
    lastUpdate:props.produk.lastUpdate,
    produkEtalase:produkEtalase
  })

  // useEffect(()=>{
  //   setInputs((prev)=>({
  //     ...prev,
  //     namaArray:hashTag
  //   }))
  // },)


  useEffect(()=>{
    setInputs((prev)=>({
      ...prev,
      ['produkEtalase']:produkEtalase
    }))
  },[produkEtalase])

  const handleTanggalMulai = (newValue) => {

    console.log('Value baru dibawah')
    
    console.log(newValue.$M+1)


    setTanggalPeriode((prev)=>({
      ...prev,
      ['mulai']:newValue.format('YYYY-MM-DD')
    }))

  }

  const handleTanggalAkhir = (newValue) => {

    console.log('Value baru dibawah')
    
    console.log(newValue)
    setTanggalPeriode((prev)=>({
      ...prev,
      ['akhir']:newValue.format('YYYY-MM-DD')
    }))

  }

  useEffect(()=>{
    getKategori()
    getBrand()
    getEtalase()
    const sekarang = dayjs().format('DD-MM-YYYY HH:mm')
    console.log(sekarang)
    setInputs((prev)=>
      ({
        ...prev,
        lastUpdate:sekarang
      })
    )

    // setInputsClear(inputs)


  },[])

  useEffect(()=>{
    // getProduk()
    if (listKategori !== null || listBrand !== null){
        console.log('Loading...')
        // setStatusLoading(false)
    }
 

  },[listKategori,listBrand])



  useEffect(()=>{
    let hargaAkhir = Number(inputs.hargaNormal)-Number(inputs.cashback)
    setHargaFinal(hargaAkhir)
    setInputs((prev)=>({
      ...prev,
      ['hargaFinal']:hargaAkhir
    }))

  },[inputs.hargaNormal,inputs.cashback])


    const handleClose = () => {
      props.setStatusEdit(false)};


    // const [kategori, setKategori] = useState('');

    // const handleKategori = (event) => {
    //   setKategori(event.target.value);
    //   console.log(event.target.value)
    // };


    useEffect(() => {
    
      console.log('Ada perubahan')
  
      setInputs((prev)=>({
        ...prev,
        periodeMulai:tanggalPeriode.mulai
      }))
  
      setInputs((prev)=>({
        ...prev,
        periodeAkhir:tanggalPeriode.akhir
      }))
  
    },[tanggalPeriode]);



    const pembuatHashTag = (kata) =>{
      const arraySementara = [kata.toLowerCase()]
        const namaPrdk = kata.toLowerCase()
  
        namaPrdk.split(' ').forEach((prdk)=>{
          if(prdk.includes("-")){
            console.log('Ada tanda strip terdeteksi')
            arraySementara.push(prdk.toLowerCase())
            arraySementara.push(prdk.replace('-','').toLowerCase())
          }
        })
        const namaPrdk2 = namaPrdk.replace("-", " ");
        const namaPrdk3 = namaPrdk.replace("-","")
        
        
        const hasilSplit = namaPrdk2.split(' ')
        const hasilSplit2 = namaPrdk.split(' ')
        const hasilSplit3 = namaPrdk3.split(' ')
        const hasilSplit4 = namaPrdk2.split(' ')
        // console.log('Ini adalah hasil split',hasilSplit)
        hasilSplit.map((el)=>arraySementara.push(el.toLowerCase()))
        // arraySementara.push(hasilSplit)
  
        const pecahKataKunci = (kataKunci) => {
  
          kataKunci.forEach((el,index)=>{
            (
              // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
              kataKunci.forEach((els,ind)=>{
                if (kataKunci[index] === kataKunci[ind]){
                  console.log('Disini ada kesamaan suku kata')
                }else{
                  (
                    arraySementara.push(kataKunci[index].toLowerCase() + ' ' + kataKunci[ind].toLowerCase())
                  )
                }
              })
            )
          })
  
        }
  
        pecahKataKunci(hasilSplit)
        pecahKataKunci(hasilSplit2)
        pecahKataKunci(hasilSplit3)
  
        hasilSplit4.forEach((el,index)=>{

          // console.log(hasilSplit.length)
          
          (
            // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
            hasilSplit4.forEach((els,ind)=>{
              console.log('Type of ind ada lah ', typeof (hasilSplit4[ind+1]), (ind+1), hasilSplit4[ind+1])
              if(hasilSplit4[ind+1] === undefined || hasilSplit4[index] === hasilSplit4[ind] || hasilSplit4[ind] === hasilSplit4[ind+1] || hasilSplit4[index] === + hasilSplit4[ind+1]){
                console.log('Disini terdapat undefined')
              }else{
                (
                  arraySementara.push(hasilSplit4[index].toLowerCase() + ' ' + hasilSplit4[ind].toLowerCase() + ' ' + hasilSplit4[ind+1])
                )
              }
             
            })
          )
                  console.log('hasil split berkali kali',hasilSplit4[index] + hasilSplit4[index+1])
    
        })
        // HASIL CODE SMENTARA

        // const hasilSplit5 = hasilSplit4.reverse()

        // hasilSplit5.forEach((el,index)=>{

        //   // console.log(hasilSplit.length)
          
        //   (
        //     // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
        //     hasilSplit5.forEach((els,ind)=>{
        //       console.log('Type of ind ada lah ', typeof (hasilSplit5[ind+1]), (ind+1), hasilSplit5[ind+1])
        //       if(hasilSplit5[ind+1] === undefined){
        //         console.log('Disini terdapat undefined')
        //       }else{
        //         (
        //           arraySementara.push(hasilSplit5[index].toLowerCase() + ' ' + hasilSplit5[ind].toLowerCase() + ' ' + hasilSplit5[ind+1])
        //         )
        //       }
             
        //     })
        //   )
        //           console.log('hasil split berkali kali',hasilSplit5[index] + hasilSplit5[index+1])
    
        // })
    
        const arrayTerbaru = [...new Set(arraySementara)]
         console.log('Ini ada lah array Baru ', arrayTerbaru)
        
        //  return arrayTerbaru
    
        setInputs(prev=>({
          ...prev,
          'namaArray':arrayTerbaru
        }))

        // console.log('Pembuat hashtag sementara berhasil ...', inputs)

        // setHashTag(arrayTerbaru)
  
    }



    // useEffect(()=>{
    //   pembuatHashTag(hashTag)
      
    // },[hashTag])
  



  const handleChange = (e) =>{
    setInputs((prev)=>({
      ...prev,
      [e.target.name] : e.target.name === "periodePromo" || e.target.name === "produkDiscontinue" ?e.target.checked:e.target.value
    }))

    
  


    // HASIL CODE SMENTARA
    
    
    if (e.target.name === 'namaProduk'){
      const arraySementara = [e.target.value.toLowerCase()]
      const namaPrdk = e.target.value.toLowerCase()

      namaPrdk.split(' ').forEach((prdk)=>{
        if(prdk.includes("-")){
          console.log('Ada tanda strip terdeteksi')
          arraySementara.push(prdk.toLowerCase())
          arraySementara.push(prdk.replace('-','').toLowerCase())
        }
      })
      const namaPrdk2 = namaPrdk.replace("-", " ");
      const namaPrdk3 = namaPrdk.replace("-","")
      
      
      const hasilSplit = namaPrdk2.split(' ')
      const hasilSplit2 = namaPrdk.split(' ')
      const hasilSplit3 = namaPrdk3.split(' ')
      const hasilSplit4 = namaPrdk2.split(' ')
      // console.log('Ini adalah hasil split',hasilSplit)
      hasilSplit.map((el)=>arraySementara.push(el.toLowerCase()))
      // arraySementara.push(hasilSplit)

      const pecahKataKunci = (kataKunci) => {

        kataKunci.forEach((el,index)=>{
          (
            // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
            kataKunci.forEach((els,ind)=>{
              if (kataKunci[index] === kataKunci[ind]){
                console.log('Disini ada kesamaan suku kata')
              }else{
                (
                  arraySementara.push(kataKunci[index].toLowerCase() + ' ' + kataKunci[ind].toLowerCase())
                )
              }
              
            })
          )
        })

      }

      pecahKataKunci(hasilSplit)
      pecahKataKunci(hasilSplit2)
      pecahKataKunci(hasilSplit3)

  

      hasilSplit4.forEach((el,index)=>{
        if(index === 'undefined'){
          console.log('Disini terdapat undefined', index)
        }
        (
          // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
          hasilSplit4.forEach((els,ind)=>{
            console.log('Type of ind ada lah ', typeof (hasilSplit4[ind+1]), (ind+1), hasilSplit4[ind+1])
            if(hasilSplit4[ind+1] === undefined){
              console.log('Disini terdapat undefined')
            }else{
              (
                arraySementara.push(hasilSplit4[index].toLowerCase() + ' ' + hasilSplit4[ind].toLowerCase() + ' ' + hasilSplit4[ind+1])
              )
            }
           
          })
        )
                console.log('hasil split berkali kali',hasilSplit4[index] + hasilSplit4[index+1])
  
      })
      
      // HASIL CODE SMENTARA

      // hasilSplit4.reverse().forEach((el,index)=>{
      //   (
      //     // arraySementara.push(hasilSplit[index] + ' ' + hasilSplit[index+1])
      //     hasilSplit4.forEach((els,ind)=>{
      //       (
      //         arraySementara.push(hasilSplit4[index].toLowerCase() + ' ' + hasilSplit4[ind].toLowerCase() + ' ' + hasilSplit4[ind+1])
      //       )
      //     })
      //   )
      //           console.log('hasil reverse berhasil ',hasilSplit4[index] + hasilSplit4[index+1])
  
      // })
  
      const arrayTerbaru = [...new Set(arraySementara)]
  
  
      setInputs((prev)=>({
        ...prev,
        'namaArray':arrayTerbaru
      }))

      // setHashTag(arrayTerbaru)

      // inputs.namaArray = arrayTerbaru
  
      // HASIL CODE SMENTARA

    }

    

  }


  useEffect(()=>{
    pembuatHashTag(props.produk.namaProduk)
  },[])




  const handleUpdate = async (e) =>{
    e.preventDefault()

    inputs.namaProduk = inputs.namaProduk.toLowerCase()
    inputs.kategori = inputs.kategori.toLowerCase()
    inputs.brand = inputs.brand.toLowerCase()

    // const hashTag = pembuatHashTag(props.produk.namaProduk)

    // inputs.namaArray = hashTag

    // setInputs(prev=>({
    //   ...prev,
    //   ...inputs.namaArray:[hashTag]
    // }))
    
    console.log('Input berhasil terupdate ', inputs.lastUpdate)
    
    console.log(inputs)

    const editRef = doc(db, 'products', inputs.id)

    await updateDoc(editRef, {
      ...inputs
    });
    alert('Berhasil Terupdate')
    handleClose()

  }
  

  return (
    <>
        <Dialog
        open={props.status}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll='body'
      >
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small" sx={{m:1}}>
                <CloseIcon fontSize="inherit" onClick={()=>{handleClose()}}/>
            </IconButton>
          </Stack>

          <DialogTitle id="alert-dialog-title">
          {"Edit Produk"}
        </DialogTitle>
        

          <Stack justifyContent="center"  sx={{m:1, p:1,}}>
        <Box component="form" onSubmit={handleUpdate}>
              <TextField name="namaProduk" label="Nama Produk" variant="outlined" sx={{width:"100%", m:'auto'}} value={inputs.namaProduk} onChange={handleChange} />

            
              <FormControl fullWidth sx={{mt:2}} required>
              <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                // defaultValue={inputs.kategori}
                value={inputs.kategori}
                label="Kategori"
                onChange={handleChange}
                name="kategori"
              >
                {listKategori?.map((kategori)=>{
                  return(
                    <MenuItem key={kategori.nama} value={titleCase(kategori.nama)}>{titleCase(kategori.nama)}</MenuItem>
                  )
                })}
              </Select>
              </FormControl>

              <FormControl fullWidth sx={{mt:2}} required>
              <InputLabel id="demo-simple-select-label">Brand</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                // defaultValue={inputs.brand}
                value={inputs.brand}
                label="Brand"
                onChange={handleChange}
                name="brand"
              >
                {listBrand?.map((brand)=>{
                  return(
                    <MenuItem key={brand.nama} value={titleCase(brand.nama)}>{titleCase(brand.nama)}</MenuItem>
                  )
                })}
              </Select>
              </FormControl>


              <TextField type="number" name="hargaModal" label="Harga Modal" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.hargaModal} onChange={handleChange}/>

              <TextField type="number" name="hargaNormal" label="Harga Normal" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.hargaNormal} onChange={handleChange} />

              <Stack direction="row" justifyContent="space-between"  alignItems="center" justifyItems={'center'} sx={{mt:1}}>
                <TextField type="number" name="cashback" label="CashBack" variant="outlined" sx={{width:"30%",m:'auto'}} value={inputs.cashback} onChange={handleChange}/>
                <Box sx={{p:1, m:1, borderRadius:2, width:"33%", textAlign:'center'}} >
                    <Stack direction="column" >
                    <Typography variant="body2">Harga Modal :</Typography>
                    <Typography variant="body1" sx={{fontWeight:'bold'}}>{Number(inputs.hargaModal).toLocaleString()}</Typography>
                    </Stack>
                  </Box>
                  <Box sx={{p:1, m:1, borderRadius:2, width:"33%", textAlign:'center'}} >
                    <Stack direction="column" >
                    <Typography variant="body2" >Harga Final :</Typography>
                    <Typography variant="body1" sx={{fontWeight:'bold', color:'#c62828'}}> {hargaFinal.toLocaleString()}</Typography>
                    </Stack>
                  </Box>
              </Stack>

              <FormControlLabel  sx={{width:"100%", m:'auto'}} control={<Switch checked={inputs.periodePromo} name='periodePromo' value={inputs.periodePromo} onChange={handleChange} /> } label="Terdapat Periode Promo ?" />

              {/* TANGGAL */}
              {inputs.periodePromo&&<Stack direction="row" alignItems="center" justifyContent="space-between" >
                
              <LocalizationProvider dateAdapter={AdapterDayjs} gap={2}>
                  <Stack spacing={3} sx={{width:'49%', mt:2}}>
                    <MobileDatePicker
                      
                      name='periodeMulai'
                      label="Periode Mulai"
                      inputFormat="DD/MM/YYYY"
                      value={tanggalPeriode.mulai}
                      onChange={handleTanggalMulai}
                      renderInput={(params) => <TextField {...params} />}
                      // disabled={!inputs.periodePromo}
                    />
                  </Stack>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{width:'49%', mt:2}}>
                    <MobileDatePicker
                      name='periodeAkhir'
                      label="Periode Akhir"
                      inputFormat="DD/MM/YYYY"
                      value={tanggalPeriode.akhir}
                      onChange={handleTanggalAkhir}
                      renderInput={(params) => <TextField {...params} />}
                      // disabled={!inputs.periodePromo}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>}

              <TextField name="garansi" label="Garansi" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.garansi} onChange={handleChange}/>
              <TextField name="linkProduk" label="Link Produk" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.linkProduk} onChange={handleChange} />

              <TextField name="free" label="Free" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.free} onChange={handleChange}/>
              <TextField name="freeKlaim" label="Free Klaim" variant="outlined" sx={{width:"100%", m:'auto', mt:2}} value={inputs.freeKlaim} onChange={handleChange}/>

              {inputs.produkDiscontinue&&<Alert severity="info" sx={{mt:1}}>Status Produk Saat Ini : Discontinue</Alert>}

              <FormControlLabel  sx={{width:"100%", m:'auto', mt:1}} control={<Switch checked={inputs.produkDiscontinue} name='produkDiscontinue' value={inputs.produkDiscontinue} onChange={handleChange} /> } label="Produk Sudah Discontinue ?" />
              


              <TextField name="keterangan" label="Keterangan / Info Free" multiline rows={4}  variant="standard" sx={{width:"100%", m:'auto', mt:2}} value={inputs.keterangan} onChange={handleChange} />

              {/* defaultValue="Default Value" BERGUNA UNTUK MASUKKAN DEFAULT VALUE */}
              {produkEtalase?.map((satuan)=>(
                <>
                {console.log(satuan)}
                <Chip key={satuan} label={titleCase(satuan)} sx={{mt:1.5, mr:1}}/>
                </>
              ))}

              <FormControl sx={{mt:1.5,width: '100%' }}>
                <InputLabel id="demo-multiple-name-label">Etalase Produk</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={produkEtalase}
                  onChange={handleMultipleSelect}
                  input={<OutlinedInput label="Etalase Produk" />}
                  MenuProps={MenuProps}
                >
                  {etalase?.map((name) => (
                    
                    <MenuItem
                      key={name.nama}
                      value={name.nama}
                      style={getStyles(name.nama, produkEtalase, theme)}
                    >
                      {titleCase(name.nama)}
                    </MenuItem>
                  ))}
                </Select>
      </FormControl>



              

              
              <Stack direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mt:2, mb:2}}>
                    <Button sx={{width:'100%', height:40}}variant="contained" endIcon={<AddIcon />} type='submit'>UPDATE</Button>
              </Stack>
            </Box>
        </Stack>

        </Stack>
      </Dialog>
    </>
  )
}
