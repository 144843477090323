import { Button, Container, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearIcon from '@mui/icons-material/Clear';
import { AppContext } from '../App';
import { useEffect } from 'react';

export const KolomCari = (getProduk) => {

    // const [cariProduk,setCariProduk] = useState(null)
    const {produkCari,setProdukCari} = useContext(AppContext)

    const cariProduk = produkCari
    const setCariProduk = setProdukCari



    const handleEnter = (event) =>{
      event.preventDefault()
      console.log('event target ',event)
        const stringCari = event.target.value.toLowerCase()
        console.log(stringCari)
        setCariProduk(stringCari)
        // console.log('sdasdasdasdasd',event.target.value.toLowerCase())
        if (event.key === 'Enter' && stringCari.length>0){
          console.log('heelo')
            
            // getProduk.setListProduk(undefined)
            // getProduk.getProduk(stringCari)
        }

        
        // setCariProduk(stringCari)
        // if (stringCari.length>0){
        //   getProduk.getProduk(stringCari)
        // }
        
      }


      const handleSubmit = (event) =>{
        // e.prefentDefault()
        event.preventDefault()
      if (cariProduk.length>1){
        console.log('Ketrigger')
        getProduk.setListProduk(undefined)
        getProduk.getProduk(cariProduk)
        }
        
      }





  return (
    <>

        <Container>
        
        <Box display="flex" justifyContent="center" alignItems='center' alignContent='center' sx={{mt:4, mr:1,ml:1}} gap={1} >
        <Box component='form' onSubmit={handleSubmit} sx={{  m:'auto', display:'block',width:'100%' }} >
        <FormControl sx={{  m:'auto', display:'block',width:'100%' }} variant="outlined" >
            <InputLabel htmlFor="outlined-adornment-password">Cari Produk</InputLabel>
            <OutlinedInput value={cariProduk} type={'text'} sx={{width:'100%', maxWidth:"100%"}} id="outlined-basic" label="Cari Produk" variant="outlined" onChange={(e)=>handleEnter(e)}  autoComplete="on"
              endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setCariProduk('')}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                  
                >
                {cariProduk&&<ClearIcon fontSize='small'/>}
                </IconButton>
              </InputAdornment>
            }
            />
        </FormControl>
        </Box>



        </Box>
        </Container>
    </>
  )
}
