import { Alert, Divider, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useNavigate } from 'react-router-dom';



export const Info = () => {
    const navigate = useNavigate()


  return (
    <>
        <Container >
        <Alert severity="info" sx={{m:1, mt:2}}>
        <Stack direction={'row'} gap={1} alignItems='center'>
            <Typography variant='body2'>BARU ! Komisi Ulasan Google </Typography>
            <Typography variant='body2' sx={{fontWeight:'bold', cursor:'pointer'}} onClick={()=>{navigate(`/ulasan-google`)}}>Cek Disini </Typography>
        </Stack>
        </Alert>

        <Alert severity="info" sx={{m:1}}>
        <Stack direction={'row'} gap={1} alignItems='center'>
            <Typography variant='body2'>Master Plan Galaxy Camera </Typography>
            <Typography variant='body2' sx={{fontWeight:'bold', cursor:'pointer'}} onClick={()=>{navigate(`/masterplan`)}}>Cek Disini </Typography>
        </Stack>
        </Alert>

        </Container>
    </>
  )
}
