import { Button, Dialog, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';


export const Konfirmasi = (props) => {
  return (
    <>
        <>
        <Dialog
        open={props.statusModalHapus}
        onClose={()=>props.setStatusModalHapus(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll='body'
      >
        <Stack sx={{m:2}}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small">
                <CloseIcon fontSize="inherit" onClick={()=>props.setStatusModalHapus(false)}/>
            </IconButton>
          </Stack>
            
            <DialogTitle id="alert-dialog-title" sx={{m:'auto'}}>
            {"Konfirmasi Hapus"}
            </DialogTitle>
            
            <Typography sx={{m:1, textAlign:'center'}}>Apakah Anda yakin ingin menghapus {props.nama}?</Typography>

            <Stack>
                <Button sx={{m:1}}  onClick={()=>props.setKonfirmasiHapus(true)} variant="contained">Hapus</Button>
                <Button sx={{m:1}} onClick={()=>props.setStatusModalHapus(false)}>Cancel</Button>
            </Stack>

        
            

        </Stack>
        

   
      </Dialog>
    </>
    </>
  )
}
