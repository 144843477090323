import { Button, Container, Divider, Fab, IconButton, Menu, MenuItem, Modal, Paper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import LinkIcon from '@mui/icons-material/Link';
import { Edit } from './Edit';
import { useNavigate,useParams } from 'react-router-dom';
import { ListAngsuran } from './ListAngsuran';
import { HargaModal } from './HargaModal';
import dayjs from 'dayjs';
import WarningIcon from '@mui/icons-material/Warning';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import VerifiedIcon from '@mui/icons-material/Verified';
import PercentIcon from '@mui/icons-material/Percent';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Konfirmasi } from './Konfirmasi';
import { useEffect } from 'react';
import { titleCase } from "title-case";
import { useContext } from 'react';
import { AppContext } from '../App';
import { motion } from "framer-motion"
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

var relativeTime = require('dayjs/plugin/relativeTime')


dayjs.extend(relativeTime)






  


export const HasilPencarian = ({produk,listProduk,setListProduk}) => {
  const {akunMaster} = useContext(AppContext)

    console.log('Ini merupakan list produk ', listProduk)
    const waktuSekarang = dayjs().format('DD/MM/YYYY')
    
    const masaPromo = dayjs(produk.periodeAkhir).from(dayjs())
    console.log('Masa Promo adalah ',produk.namaProduk, masaPromo)

   
    const [anchorEl, setAnchorEl] = useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl(null);
    };


    const [lihatangsuran,setLihatAngsuran] = useState(false)
    const [statusedit,setStatusEdit] = useState(false)

    const [statusmodal,setStatusModal] = useState(false)


    



    const tanggalSekarang = dayjs()
    const tanggalBerakhir = dayjs(produk.periodeAkhir).format('YYYY-MM-DD')
    const promoBerakhir = tanggalSekarang.diff(tanggalBerakhir, 'day')
    const statusPromo = Math.sign(promoBerakhir)


        // Berfungsi untuk konfirmasi hapus produk
    const [statusModalHapus,setStatusModalHapus] = useState(false)
    const [konfirmasiHapus,setKonfirmasiHapus] = useState(false)

    const hapusProduk = () =>{
      setStatusModalHapus(true)
      
  }

  const deleteProduk = async () =>{
    const idHapus = produk.id
    const postRef = doc(db,"products",idHapus);
    await deleteDoc(postRef)
    setListProduk(listProduk.filter((pro)=> pro.id !== idHapus))
  }

  useEffect(()=>{
    if(konfirmasiHapus){
      console.log('Produk berhasil terhapus dari sistem')
      setStatusModalHapus(false)
      deleteProduk()
      handleClose1()
    }
  },[konfirmasiHapus])
    


  return (
    <>

  
         {/* <Stack display="flex" justifyContent="center" sx={{ p:0.5,}} gap={1}>
            <Paper sx={{width:1110, maxWidth:"95%", p:1.2, m:"auto", borderRadius:1 }} elevation={3} onClick={()=>setLihatAngsuran(true)} >
            <Box display="flex"   justifyContent="space-between"   alignItems="center"  >

              <Box display="flex" gap={0.2} alignItems="center" >
                <IconButton aria-label="delete" size="small" onClick={() => window.open('https://galaxycamera.id/cicilan-tanpa-cc')}>
                    <LinkIcon color={produk.linkProduk?"":"secondary"} fontSize='small'/>
                  </IconButton>

                  {statusPromo === 1&&<ReportGmailerrorredIcon color='secondary' fontSize='small'/>}
                  {produk.produkDiscontinue&&<CloseIcon color='secondary' fontSize='small'/>}
                <Typography sx={{cursor:'pointer'}} variant='subtitle2' fontSize='small' >{produk.namaProduk} </Typography>
              </Box>
                
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant='body2' sx={{color:'#78909c'}}>Rp {Number(produk.hargaFinal).toLocaleString()} </Typography> */}
                  {/* <Button  variant="contained" color='secondary' onClick={()=>setLihatAngsuran(true)}>Lihat</Button> */}

          

                  {/* <IconButton aria-label="delete" size="small" onClick={handleClick}>
                      <MoreVertIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
            </Box> */}

            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>{setStatusEdit(true)}}>Edit</MenuItem>
                <MenuItem onClick={()=>{setStatusModal(true)}}>Cek Modal</MenuItem>
                <MenuItem onClick={handleClose1}>Hapus</MenuItem>
            </Menu>
            </Paper> */}


  {/* </Stack>  */}
  


  <ListAngsuran lihatangsuran={lihatangsuran} setLihatAngsuran={setLihatAngsuran} produk={produk} statusPromo={statusPromo}/>
  
  <Edit status={statusedit} setStatusEdit={setStatusEdit} produk={produk}/>
  <HargaModal statusmodal={statusmodal} setStatusModal={setStatusModal} produk={produk}/>
  <Konfirmasi statusModalHapus={statusModalHapus} setStatusModalHapus={setStatusModalHapus} nama={produk.namaProduk} setKonfirmasiHapus={setKonfirmasiHapus}/>





  <Container>
    <Paper sx={{p:1.2, m:1}} elevation={3}>
      <Stack direction={'row'} alignItems="center">
            
          <IconButton aria-label="delete" size="small" onClick={() => window.open(produk.linkProduk?produk.linkProduk:'#')}>
            <LinkIcon color={produk.linkProduk?"primary":"secondary"} fontSize='small'/>
          </IconButton>

          {produk.hargaModal&&<IconButton aria-label="delete" size="small" onClick={() => window.open(produk.linkProduk?produk.linkProduk:'#')}>
            <LocalFireDepartmentIcon  fontSize='small' sx={{color:'#ff5252'}}/>
          </IconButton>}
          
          <Stack direction="row"  justifyContent="space-between" alignItems="center" sx={{cursor:'pointer', width:'100%'}} onClick={()=>setLihatAngsuran(true)}>
            
              {/* {produk.produkDiscontinue&&<CloseIcon color='secondary' fontSize='small'/>} */}
              <Typography  variant='subtitle2' fontSize='small' sx={{textDecoration:`${produk.produkDiscontinue&&'line-through'}`, color:`${produk.produkDiscontinue&&'#bdbdbd'}`}} >
              {titleCase(produk.namaProduk)}
              </Typography>

              <Stack direction="row" alignItems="center" >
            <Typography variant='body2' sx={{color:'#78909c', mr:1}}>Rp {Number(produk.hargaFinal).toLocaleString()} </Typography>


            {produk.periodePromo === false?'':
            
             statusPromo === -1?<PercentIcon sx={{mr:1, color:'#78909c'}} fontSize='small'/>:statusPromo === 1?<motion.div
    animate={{
      scale: [1, 2, 2, 1, 1]}}
  ><WarningAmberIcon sx={{mr:1, color:'#c62828'}} fontSize='small'/></motion.div>:''
            }


           

            </Stack>
          </Stack>

          {akunMaster&&<IconButton aria-label="delete" size="small" onClick={handleClick}>
                <MoreVertIcon fontSize="inherit" />
            </IconButton>}
            

      </Stack>
    </Paper>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >   
                <MenuItem onClick={()=>{setStatusEdit(true)}} >Edit</MenuItem>
                <MenuItem onClick={()=>{setStatusModal(true)}}>Cek Modal</MenuItem>
                <MenuItem onClick={()=>hapusProduk(produk.id)}>Hapus</MenuItem>
            </Menu>
    </Container>
  
    </>
  )
}
