import { Button, Container, Divider, IconButton, Modal, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export const MintaUlasan = () => {


    const onCopyText = () => {
        console.log('Berhasil Tercopy')
        
      };


    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  


  return (
    <Container>
        <Stack sx={{mt:3, mb:20}}>
          
          <Stack direction={'row'} sx={{m:'auto', mb:2}} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} variant="h5" >ULASAN GOOGLE</Typography>
          </Stack>

          <Typography variant='body2'>Untuk meningkatkan penjualan segi offline dan pencarian Google teratas. Diharapkan setiap sales wajib meminta support ulasan dari setiap customer yang berbelanja di Galaxy Camera</Typography>
          <Typography variant="body2"> Seperti yang kita semua tahu, persaingan di industri kamera semakin ketat, terutama dengan adanya toko kamera seperti Doss, JPC Kemang, dan Vista Kamera Solo yang sudah berbenah diri dan memiliki banyak ulasan positif di Google.</Typography>
          
          <Stack sx={{mt:2}}>
          <Typography sx={{color:'#ab003c'}} fontWeight={'bold'} variant="body2">- Galaxy Camera Tangerang : -+ 125 ulasan (Update terakhir : 27 Maret 2023)</Typography>
          <Typography sx={{color:'#ab003c'}} fontWeight={'bold'} variant="body2">- Galaxy Camera Depok : -+ 46 ulasan (Update terakhir : 27 Maret 2023)</Typography>


          <Typography sx={{color:'#616161'}} fontWeight={'bold'} variant="body2">- JPC Kemang : -+ 9000 ulasan</Typography>
          <Typography  sx={{color:'#616161'}} fontWeight={'bold'} variant="body2">- DOSS : -+ 650 ulasan</Typography>
          <Typography  sx={{color:'#616161'}} fontWeight={'bold'} variant="body2">- Vista Kamera : -+ 1277 ulasan</Typography>
          <Typography  sx={{color:'#616161'}} fontWeight={'bold'} variant="body2">- Sinar Bahagia : -+ 360 ulasan</Typography>
          <Typography  sx={{color:'#616161'}} fontWeight={'bold'} variant="body2">- Bursa Kamera Profesional Fatmawati : -+ 205 ulasan</Typography>

          

          </Stack>


          <Typography sx={{mt:2}} variant='body2'>Mengapa ulasan di Google itu penting? Pertama-tama, ulasan di Google dapat membantu meningkatkan kredibilitas toko kita di mata konsumen. Konsumen cenderung mencari ulasan sebelum memutuskan untuk membeli produk atau menggunakan jasa, dan toko dengan banyak ulasan positif cenderung lebih dipercaya.</Typography>
          <Typography sx={{mt:2}} variant='body2'>Mari berikan pelanggan pengalaman yang baik dan memuaskan sehingga mereka bersedia memberikan ulasan positif di Google. Mari kita bekerja sama untuk memperbaiki reputasi toko kita dan meningkatkan kepercayaan konsumen pada kita. </Typography>

          <Typography variant='body2' fontWeight={'bold'} sx={{mt:2}}>Tips meminta ulasan kepada Customer :</Typography>
          <Box sx={{mt:1, color:'#01579b', backgroundColor:'#e1f5fe', p:2, borderRadius:2}}>
              <Stack>
                  <Typography variant='body2' >- Meminta dengan Sopan dan tidak terkesan memaksa.</Typography>
                  <Typography variant='body2' >- Kenali Customer yang berpotensi memberi rating kurang (Tidak semua customer wajib dimintai ulasan, diselektif berdasarkan interaksi dan kenyamanan komunikasi).</Typography>
              </Stack>
          </Box>
          <Typography variant='body2' fontWeight={'bold'} sx={{mt:2}}>Contoh :</Typography>
          <Box sx={{mt:1, color:'#01579b', backgroundColor:'#e1f5fe', p:2, borderRadius:2}}>
              <Typography variant='body2' >- Hi ka, Jika berkenan boleh minta support dari kaka untuk memberi ulasan ditoko kami di Google ?</Typography>
          </Box>

          <Typography variant='body2' fontWeight={'bold'} sx={{mt:2, color:'#ab003c'}}>NEW! Komisi Per Ulasan :</Typography>
          <Box sx={{mt:1, color:'#01579b', backgroundColor:'#e1f5fe', p:2, borderRadius:2}}>
              <Typography variant='body2' >- Setiap Ulasan yang di Report di App Ulasan akan mendapatkan komisi sebesar <strong>Rp 7.500</strong>  per ulasan.</Typography>
              <Typography variant='body2' >- Tidak ada minimal harga produk yang dibeli customer.</Typography>
              <Typography variant='body2' >- Nominal bonus per-ulasan akan berubah sewaktu-waktu berlaku sesuai info di halaman ini.</Typography>

              <br></br>
              <Typography variant='body2' >Link Browser PC : <strong>https://www.appsheet.com/start/f52601f1-7638-4204-b487-36332420395e</strong></Typography>
              <Typography variant='body2' >Link Install HP : <strong>https://www.appsheet.com/newshortcut/f52601f1-7638-4204-b487-36332420395e</strong></Typography>
          </Box>
          <Divider sx={{m:2}}></Divider>


          <Typography variant="h6" sx={{m:'auto', mt:2}}>Link Google Toko Tangerang</Typography>

          <CopyToClipboard  text={'https://g.page/r/CR-hpVW6BnCREBM/review'} onCopy={()=>onCopyText()}>
          <Typography variant='body' sx={{m:'auto'}} >https://g.page/r/CR-hpVW6BnCREBM/review <IconButton aria-label="delete"><ContentCopyIcon /></IconButton></Typography>
          </CopyToClipboard>

          <Button sx={{width:'200px', m:'auto'}} variant="contained" onClick={handleOpen1}>BUKA BARCODE</Button>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Box sx={style}>
          <Typography variant="h6">Galaxy Camera Tangerang</Typography>
          <Box
          component="img"
          alt="Galaxy Camera Tangerang"
          src="https://galaxycamera.id/media/images/galaxytangerang.png"
          sx={{height:300, m:'auto', display:'flex',
          maxHeight:{xs:300, md:300}
        }}
      /> 
          </Box>
        </Modal>








          <Divider sx={{m:2}}></Divider>





          
          <Typography variant="h6" sx={{m:'auto', mt:2}}>Link Google Toko Depok</Typography>

          <CopyToClipboard  text={'https://g.page/r/Ced503DrWMhSEBM/review'} onCopy={()=>onCopyText()}>
          <Typography variant='body' sx={{m:'auto'}} >https://g.page/r/Ced503DrWMhSEBM/review <IconButton aria-label="delete"><ContentCopyIcon /></IconButton></Typography>
          </CopyToClipboard>


          <Button sx={{width:'200px', m:'auto'}} variant="contained" onClick={handleOpen}>BUKA BARCODE</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Box sx={style}>
          <Typography variant="h6">Galaxy Camera Depok</Typography>
          <Box
          component="img"
          alt="Galaxy Camera Depok"
          src="https://galaxycamera.id/media/images/galaxydepok.png"
          sx={{height:300, m:'auto', display:'flex',
          maxHeight:{xs:300, md:300}
        }}
      /> 
          </Box>
        </Modal>


        </Stack>
    </Container>
  )
}
