import { Alert, Dialog, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';


export const HargaModal = (props) => {


  return (
    <>
         <>
        <Dialog
        open={props.statusmodal}
        onClose={()=>props.setStatusModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll='body'
      >
        <Stack sx={{m:2}}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton  aria-label="delete" size="small">
                <CloseIcon fontSize="inherit" onClick={()=>props.setStatusModal(false)}/>
            </IconButton>
          </Stack>

            <DialogTitle id="alert-dialog-title">
            {"Harga Modal"}
            </DialogTitle>

        
            <Stack direction={'row'} gap={1}>
                <Typography>Harga Modal : </Typography>
                <Typography>{Number(props.produk.hargaModal).toLocaleString()}</Typography>
            </Stack>

            <Stack direction={'row'} gap={1}>
                <Typography>Harga Jual : </Typography>
                <Typography>{Number(props.produk.hargaFinal).toLocaleString()}</Typography>
            </Stack>

            <Divider sx={{mt:2, mb:2}}/>


            <Stack direction={'row'} gap={1}>
                <Typography>Cashback : </Typography>
                <Typography>{Number(props.produk.cashback).toLocaleString()}</Typography>
            </Stack>

            <Divider sx={{mt:2, mb:2}}/>

            {props.produk.hargaModal==0?(<>
              <Alert severity="error">Harga Modal Tidak Tersedia</Alert>
            </>):(<>
              <Stack  direction={'row'} gap={1} sx={{backgroundColor:'#e1f5fe', p:1, borderRadius:2, color:'#01579b'}}>
                <Typography fontWeight={'bold'}>Margin : </Typography>
                <Typography fontWeight={'bold'}>{(Number(props.produk.hargaNormal)-Number(props.produk.hargaModal)).toLocaleString()}</Typography>
            </Stack>

            </>)}


        </Stack>
        

   
      </Dialog>
    </>
    </>
  )
}
