import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { AppContext } from '../App';
import DnsIcon from '@mui/icons-material/Dns';

export const Footer = () => {
  const {akunMaster} = useContext(AppContext)


    const [value, setValue] = useState(0);
    
      


  return (
    <>
    <Paper  elevation={6}  sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,display: { xs: 'none', sm: 'block' }}}>
      <BottomNavigation 
        
        showLabels
        value={value}
        onChange={(event, newValue) => {
          console.log(newValue)
          setValue(newValue);
        }}
        
      >

        <BottomNavigationAction  label="Home" icon={<HomeIcon />} component={Link}  to="/" />
        <BottomNavigationAction  label="Kategori Produk" icon={<CategoryIcon />} component={Link}  to="/kategori" />
        {akunMaster&&<BottomNavigationAction label="Add Product" icon={<AddBoxIcon />} component={Link}  to="/add-product" />}
        <BottomNavigationAction  label="Brand" icon={<FormatListBulletedIcon />} component={Link}  to="/brands" />
        <BottomNavigationAction  label="Etalase" icon={<DnsIcon />} component={Link}  to="/etalase" />


      </BottomNavigation>
      </Paper>

      <Paper  elevation={3}  sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,p:1 ,display: { xs: 'block', sm: 'none' }}}>
      
      <BottomNavigation 
        showLabels
        value={value}
        onChange={(event, newValue) => {
          console.log(newValue)
          setValue(newValue);
        }}
        
      >

        <BottomNavigationAction label="Home" icon={<HomeIcon />} component={Link}  to="/" />
        <BottomNavigationAction label="Kategori" icon={<CategoryIcon />} component={Link}  to="/kategori" />
        {akunMaster&&<BottomNavigationAction label="Tambah" icon={<AddBoxIcon />} component={Link}  to="/add-product" />}
        <BottomNavigationAction label="Brands" icon={<FormatListBulletedIcon />} component={Link}  to="/brands" />
        <BottomNavigationAction label="Etalase" icon={<DnsIcon />} component={Link}  to="/etalase" />

      </BottomNavigation>
      </Paper>
    </>
  )
}
