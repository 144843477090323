import { Box, Button, Chip, Container, Divider, FormControl, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Stack } from '@mui/system';


export const HitungCuan = () => {

    const [hitungCuan, setHitungCuan] = useState({
      hargaProduk : null,
      hargaModal : null,
      voucherProduk : null,
    })

  
const biayaLayananToped2 = 1.8
const biayaAdmTopedKatA = 2.5
const biayaAdmTopedKatB = 3
const biayaAdmTopedKatC = 4

const biayaFreeOngkir2 = 4

    //Variable Utama
// const hargaProduk = 2999000
// const hargaModal = 2905000
// const voucherProduk = 0
const biayaOngkir = 0 //Hanya berlaku untuk akulaku

//Variable Potongan
const hargaAkhir = (hitungCuan.hargaProduk - hitungCuan.voucherProduk)

//Biaya Admin Tokopedia
const biayaAdminTokopediaKatA = (hargaAkhir * biayaAdmTopedKatA) / 100
const biayaAdminTokopediaKatB = (hargaAkhir * biayaAdmTopedKatB) / 100
const biayaAdminTokopediaKatC = (hargaAkhir * biayaAdmTopedKatC) / 100
let biayaLayananTokped = (hargaAkhir * biayaLayananToped2) / 100

//Biaya Admin Shopee
const biayaAdmShopeeA = 6.5
const biayaAdmShopeeB = 5.5
const biayaAdmShopeeC = 4
const biayaAdmShopeeD = 3.3
const biayaAdmShopeeE = 3.3


const biayaAdminShopeeKatA = (hargaAkhir * biayaAdmShopeeA) / 100
const biayaAdminShopeeKatB = (hargaAkhir * biayaAdmShopeeB) / 100
const biayaAdminShopeeKatC = (hargaAkhir * biayaAdmShopeeC) / 100
const biayaAdminShopeeKatD = (hargaAkhir * biayaAdmShopeeD) / 100
const biayaAdminShopeeKatE = (hargaAkhir * biayaAdmShopeeE) / 100

//Biaya Free Ongkir Tokopedia & Shopee
let biayaFreeOngkir = hargaAkhir * biayaFreeOngkir2 / 100

//Biaya Admin Akulaku
const biayaAdmAkulaku = 1.5
const biayaAdminAkulaku = (hargaAkhir * biayaAdmAkulaku) / 100
const biayaLayananAkulaku = ((hargaAkhir + biayaOngkir) * biayaAdmAkulaku) / 100

//Biaya Admin Bukalapak
const biayaAdmBukalapak = 2
const biayaAdminBukalapak = (hargaAkhir * biayaAdmBukalapak) / 100

//Biaya Admin Blibli
const biayaAdmBlibli  = 2
const biayaAdminBlibli = (hargaAkhir * biayaAdmBlibli) / 100

//Biaya Admin Lazada
const biayaAdmLazada = 1.821
const biayaAdminLazada = (hargaAkhir * biayaAdmLazada) / 100

//Conditional Ongkir
if (biayaFreeOngkir > 10000) {
	  biayaFreeOngkir = 10000

}
else {
	biayaFreeOngkir = biayaFreeOngkir

}

//Conditional Biaya Layanan Tokopedia
if (biayaLayananTokped > 50000) {
	biayaLayananTokped = 50000
    
}
else {
	biayaLayananTokped= biayaLayananTokped

}

//Cuan Tokopedia

//Cuan Tokopedia Kategori A
//Kamera Analog, Kamera Digital, Kamera Pengintai, Cleaning Tools Kamera, Lensa & Aksesoris, Perangkat Keamanan, Memory Card, Monitor, Media Penyimpanan Data.
const cuanTokopediaA = ((hargaAkhir - biayaAdminTokopediaKatA) - (biayaFreeOngkir + biayaLayananTokped)) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Tokopedia Kategori A = " + cuanTokopediaA)

//Cuan Tokopedia Kategori B
//Drone & Aksesoris (Drone Kamera, Drone Remote Kontrol)
const cuanTokopediaB = ((hargaAkhir - biayaAdminTokopediaKatB) - (biayaFreeOngkir + biayaLayananTokped)) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Tokopedia Kategori B = " + cuanTokopediaB)

//Cuan Tokopedia Kategori C
//Aksesoris Kamera, Audio, Baterai & Charger Kamera, Drone & Aksesoris (Aksesoris Drone, Tas Drone), Frame, Album & Roll Film, Lighting & Studio, Tas & Case Kamera, Perangkat Elektronik Lainnya.
const cuanTokopediaC = ((hargaAkhir - biayaAdminTokopediaKatC) - (biayaFreeOngkir + biayaLayananTokped)) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Tokopedia Kategori C = " + cuanTokopediaC)


//Cuan Shopee
//Cuan Shopee Kategori A
//Handphone & Aksesoris lainnya, Smartwatch & Fitness Tracker, Perangkat Wearable lainnya, Tongsis, Phone Holder, Kabel & Konverter Audio & Video, Gimbal & Stabilizer, Lighting & Perlengkapan Studio Foto, Roll Film & Kertas Foto, Printer Foto, Charger Baterai, Baterai & Battery Grip, Tripod, Monopod, & Aksesoris, Aksesoris Kamera lainnya, Dry Box & Cabinet, Cleaning Kit, Silica Gel, Blower, Lenspen & Brush, Perawatan Kamera lainnya, Kamera & Drone lainnya.
const cuanShopeeA = ((hargaAkhir - biayaAdminShopeeKatA) - biayaFreeOngkir) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Shopee Kategori A = " + cuanShopeeA)

//Cuan Shopee Kategori B
//Amplifier & Mixer, Kamera CCTV
const cuanShopeeB = ((hargaAkhir - biayaAdminShopeeKatB) - biayaFreeOngkir) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Shopee Kategori B = " + cuanShopeeB)

//Cuan Shopee Kategori C
//Kartu Memori, Earphone, Headphone, & Headset, Voice Recorder, Media Player lainnya, Mikrofon & Aksesoris, Speaker, AV Receiver, Perangkat Audio & Speaker lainnya, Audio lainnya, Kamera Keamanan, Lensa, Aksesoris Lensa, Aksesoris Kamera lainnya
const cuanShopeeC = ((hargaAkhir - biayaAdminShopeeKatC) - biayaFreeOngkir) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Shopee Kategori C = " + cuanShopeeC)

//Cuan Shopee Kategori D
//Kamera Pocket, Kamera Mirrorless, Kamera Action, Camcorder, Kamera Instan, Kamera Analog, DSLR, Kamera & Drone lainnya.
const cuanShopeeD = ((hargaAkhir - biayaAdminShopeeKatD) - biayaFreeOngkir) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Shopee Kategori D = " + cuanShopeeD)

//Cuan Shopee Kategori E
//Flash, Aksesoris Flash, Drone, Aksesoris Drone.
const cuanShopeeE = ((hargaAkhir - biayaAdminShopeeKatE) - biayaFreeOngkir) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Shopee Kategori E = " + cuanShopeeE)


//Cuan Akulaku
const cuanAkulaku = ((hargaAkhir - biayaAdminAkulaku) - biayaLayananAkulaku) - hitungCuan.hargaModal
console.log("Total Pendapatan Bersih Akulaku = " + cuanAkulaku)

//Cuan Bukalapak
const cuanBukalapak = ((hargaAkhir - biayaAdminBukalapak) - hitungCuan.hargaModal)
console.log("Total Pendapatan Bersih Bukalapak = " + cuanBukalapak)

//Cuan Blibli
const cuanBlibli = ((hargaAkhir - biayaAdminBlibli) - hitungCuan.hargaModal)
console.log("Total Pendapatan Bersih Blibli = " + cuanBlibli)

//Cuan Lazada
const cuanLazada = ((hargaAkhir - biayaAdminLazada) - hitungCuan.hargaModal)
console.log("Total Pendapatan Bersih Lazada = " + cuanLazada)


    const handleEnter = (event) =>{
        event.preventDefault()
        console.log('event target ',event)
          const stringCari = event.target.value.toLowerCase()
          console.log(stringCari)
          // setHargaProduk(stringCari)
          // console.log('sdasdasdasdasd',event.target.value.toLowerCase())
          if (event.key === 'Enter' && stringCari.length>0){
            console.log('heelo')
              
              // getProduk.setListProduk(undefined)
              // getProduk.getProduk(stringCari)
          }
  
          
          // setCariProduk(stringCari)
          // if (stringCari.length>0){
          //   getProduk.getProduk(stringCari)
          // }
          
        }


    const handleSubmit = (event) =>{
        // e.prefentDefault()
        event.preventDefault()
      // if (hargaProduk.length>1){
      //   console.log('Ketrigger')
      //   // getProduk.setListProduk(undefined)
      //   // getProduk.getProduk(cariProduk)
      //   }
        
      }

      const handleChange = (e) =>{
        // console.log(e.target.value)

        setHitungCuan((prev)=>({
            ...prev,
            [e.target.name]:e.target.value
        }))

        // console.log(hitungCuan)

      }

      const handleClear = () =>{

        setHitungCuan((prev)=>(
          { ...prev,
            hargaProduk : '',
            hargaModal : '',
            voucherProduk : '',
          }
        )
          
        )
      }




  return (
    <>

        <Container sx={{mb:20}}>
        
        <Box display="flex" justifyContent="center" alignItems='center' alignContent='center' sx={{mt:4, mr:1,ml:1}} gap={1} >
            <Box component='form' onSubmit={handleSubmit} sx={{  m:'auto', display:'block',width:'100%' }} >
              <Stack direction={'row'}>
                <TextField type="number" name="hargaProduk" value={hitungCuan.hargaProduk} label="Harga Jual" variant="outlined" sx={{width:'50%'}}  onChange={handleChange} />
                <Typography fontWeight={'bold'} sx={{m:'auto', color:'#c62828'}} variant='h5'>Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
              </Stack>


              <Stack direction={'row'}>
                <TextField type="number" name="hargaModal" value={hitungCuan.hargaModal} label="Harga Modal" variant="outlined"  sx={{width:'50%',mt:1}} onChange={handleChange} />
                <Typography fontWeight={'bold'} sx={{m:'auto', color:'#c62828'}} variant='h5'>Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
              </Stack>

              <Stack direction={'row'}>
                <TextField type="number" name="voucherProduk" value={hitungCuan.voucherProduk} label="Voucher" variant="outlined"  sx={{width:'50%',mt:1}} onChange={handleChange} />
                <Typography fontWeight={'bold'} sx={{m:'auto'}} variant='h5'>Rp {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>

              </Stack>
                    <Button onClick={handleClear} variant="outlined" fullWidth sx={{mt:1}}>Clear</Button>

            </Box>

        


        </Box>

        <Stack direction={'row'}  gap={1} sx={{m:1,display:'flex', flexWrap:'wrap'}}>
        <Chip sx={{cursor:'pointer'}} component={Link} href="#tokopedia" variant="outlined" size="small" label="Tokopedia" />
        <Chip sx={{cursor:'pointer'}} component={Link} href="#shopee" variant="outlined" size="small" label="Shopee" />
        <Chip sx={{cursor:'pointer'}} component={Link} href="#akulaku" variant="outlined" size="small" label="Akulaku" />
        <Chip sx={{cursor:'pointer'}} component={Link} href="#bukalapak" variant="outlined" size="small" label="Bukalapak" />
        <Chip sx={{cursor:'pointer'}} component={Link} href="#lazada" variant="outlined" size="small" label="Lazada" />
        <Chip sx={{cursor:'pointer'}} component={Link} href="#blibli" variant="outlined" size="small" label="Blibli" />        </Stack>
        <Divider sx={{mt:1,mr:1,ml:1}}/>

        <Box id="tokopedia" sx={{backgroundColor:'#e8f5e9', p:1, borderRadius:3, m:1, mb:2, p:2}}>

            <Typography sx={{ color:'#1b5e20'}} variant='subtitle2'>Tokopedia Official Store  </Typography>
            <Divider sx={{m:1}}/>

            <Typography sx={{ color:'#1b5e20'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#1b5e20'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#1b5e20'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>
            
            <Stack>
            <Typography sx={{ color:'#1b5e20'}} variant='caption'>Biaya Layanan : {biayaLayananToped2}% Rp {biayaLayananTokped.toLocaleString()} (Maks 50rb)</Typography>
            <Typography sx={{ color:'#1b5e20'}} variant='caption'>Biaya Free Ongkir : {biayaFreeOngkir2}% Rp {biayaFreeOngkir.toLocaleString()} (Maks 10rb)</Typography>
            </Stack>
            
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
            <Typography sx={{ color:'#1b5e20'}} variant='subtitle2'>Kategori A </Typography>
            <Typography sx={{ color:'#1b5e20'}} variant='body2'>(Biaya Admin {biayaAdmTopedKatA}% - Rp {biayaAdminTokopediaKatA.toLocaleString()})</Typography>
            </Stack>
            <Typography sx={{ color:'#1b5e20'}} variant='caption'>Kamera Analog, Kamera Digital, Kamera Pengintai, Cleaning Tools Kamera, Lensa & Aksesoris, Perangkat Keamanan, Memory Card, Monitor, Media Penyimpanan Data.</Typography>
            <Box sx={{backgroundColor:'#1b5e20',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanTokopediaA.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
            <Typography sx={{ color:'#1b5e20'}} variant='subtitle2'>Kategori B </Typography>
            <Typography sx={{ color:'#1b5e20'}} variant='body2'>(Biaya Admin {biayaAdmTopedKatB}% - Rp {biayaAdminTokopediaKatB.toLocaleString()})</Typography>
            </Stack>
            <Typography sx={{ color:'#1b5e20'}} variant='caption'>Drone & Aksesoris (Drone Kamera, Drone Remote Kontrol)</Typography>
            <Box sx={{backgroundColor:'#1b5e20',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanTokopediaB.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
              <Typography sx={{ color:'#1b5e20'}} variant='subtitle2'>Kategori C </Typography>
              <Typography sx={{ color:'#1b5e20'}} variant='body2'>(Biaya Admin {biayaAdmTopedKatC}% - Rp {biayaAdminTokopediaKatC.toLocaleString()})</Typography>
              </Stack>
            <Typography sx={{ color:'#1b5e20'}} variant='caption'>Aksesoris Kamera, Audio, Baterai & Charger Kamera, Drone & Aksesoris (Aksesoris Drone, Tas Drone), Frame, Album & Roll Film, Lighting & Studio, Tas & Case Kamera, Perangkat Elektronik Lainnya.</Typography>
            <Box sx={{backgroundColor:'#1b5e20',  borderRadius: '5px', p:1,  mt:1 }}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanTokopediaC.toLocaleString()}</Typography>
            </Box>

        </Box>



        <Box id="shopee" sx={{backgroundColor:'#fff3e0', p:1, borderRadius:3, m:1, mb:2, p:2}}>

            <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Shopee Star+ Seller </Typography>
            <Divider sx={{m:1}}/>

            <Typography sx={{ color:'#e65100'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#e65100'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#e65100'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>
            
            <Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Biaya Free Ongkir : {biayaFreeOngkir2}% Rp {biayaFreeOngkir.toLocaleString()} (Maks 10rb)</Typography>
            </Stack>
            
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
            <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Kategori A </Typography>
            <Typography sx={{ color:'#e65100'}} variant='body2'>(Biaya Admin {biayaAdmShopeeA}% - Rp {biayaAdminShopeeKatA.toLocaleString()})</Typography>
            </Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Handphone & Aksesoris lainnya, Smartwatch & Fitness Tracker, Perangkat Wearable lainnya, Tongsis, Phone Holder, Kabel & Konverter Audio & Video, Gimbal & Stabilizer, Lighting & Perlengkapan Studio Foto, Roll Film & Kertas Foto, Printer Foto, Charger Baterai, Baterai & Battery Grip, Tripod, Monopod, & Aksesoris, Aksesoris Kamera lainnya, Dry Box & Cabinet, Cleaning Kit, Silica Gel, Blower, Lenspen & Brush, Perawatan Kamera lainnya, Kamera & Drone lainnya.</Typography>
            <Box sx={{backgroundColor:'#e65100',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanShopeeA.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
            <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Kategori B </Typography>
            <Typography sx={{ color:'#e65100'}} variant='body2'>(Biaya Admin {biayaAdmShopeeB}% - Rp {biayaAdminShopeeKatB.toLocaleString()})</Typography>
            </Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Amplifier & Mixer, Kamera CCTV</Typography>
            <Box sx={{backgroundColor:'#e65100',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanShopeeB.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
              <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Kategori C </Typography>
              <Typography sx={{ color:'#e65100'}} variant='body2'>(Biaya Admin {biayaAdmShopeeC}% - Rp {biayaAdminShopeeKatC.toLocaleString()})</Typography>
              </Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Kartu Memori, Earphone, Headphone, & Headset, Voice Recorder, Media Player lainnya, Mikrofon & Aksesoris, Speaker, AV Receiver, Perangkat Audio & Speaker lainnya, Audio lainnya, Kamera Keamanan, Lensa, Aksesoris Lensa, Aksesoris Kamera lainnya</Typography>
            <Box sx={{backgroundColor:'#e65100',  borderRadius: '5px', p:1,  mt:1 }}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanShopeeC.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>


            <Stack direction={'row'} alignItems='center' gap={1}>
              <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Kategori D </Typography>
              <Typography sx={{ color:'#e65100'}} variant='body2'>(Biaya Admin {biayaAdmShopeeD}% - Rp {biayaAdminShopeeKatD.toLocaleString()})</Typography>
              </Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Kamera Pocket, Kamera Mirrorless, Kamera Action, Camcorder, Kamera Instan, Kamera Analog, DSLR, Kamera & Drone lainnya.</Typography>
            <Box sx={{backgroundColor:'#e65100',  borderRadius: '5px', p:1,  mt:1 }}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanShopeeD.toLocaleString()}</Typography>
            </Box>
            <Divider sx={{m:1}}/>

            <Stack direction={'row'} alignItems='center' gap={1}>
              <Typography sx={{ color:'#e65100'}} variant='subtitle2'>Kategori E </Typography>
              <Typography sx={{ color:'#e65100'}} variant='body2'>(Biaya Admin {biayaAdmShopeeE}% - Rp {biayaAdminShopeeKatE.toLocaleString()})</Typography>
              </Stack>
            <Typography sx={{ color:'#e65100'}} variant='caption'>Flash, Aksesoris Flash, Drone, Aksesoris Drone.</Typography>
            <Box sx={{backgroundColor:'#e65100',  borderRadius: '5px', p:1,  mt:1 }}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanShopeeE.toLocaleString()}</Typography>
            </Box>


        </Box>




        <Box id="akulaku" sx={{backgroundColor:'#ffebee', p:1, borderRadius:3, m:1, mb:2, p:2}}>

            <Typography sx={{ color:'#b71c1c'}} variant='subtitle2'>Akulaku  </Typography>
            <Divider sx={{m:1}}/>

            <Typography sx={{ color:'#b71c1c'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#b71c1c'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#b71c1c'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>
            
            <Stack>
            <Typography sx={{ color:'#b71c1c'}} variant='body2'>Biaya Admin : {biayaAdmAkulaku}% Rp {biayaAdminAkulaku.toLocaleString()}</Typography>
            <Typography sx={{ color:'#b71c1c'}} variant='body2'>Biaya Layanan : Rp {biayaLayananAkulaku.toLocaleString()} </Typography>
            </Stack>
            
            <Divider sx={{m:1}}/>

            <Box sx={{backgroundColor:'#b71c1c',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanAkulaku.toLocaleString()}</Typography>
            </Box>
    


        </Box>


        
        <Box id="bukalapak" sx={{backgroundColor:'#fce4ec', p:1, borderRadius:3, m:1, mb:2, p:2}}>

            <Typography sx={{ color:'#880e4f'}} variant='subtitle2'>Bukalapak  </Typography>
            <Divider sx={{m:1}}/>

            <Typography sx={{ color:'#880e4f'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#880e4f'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
            <Typography sx={{ color:'#880e4f'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>
            
            <Stack>
            <Typography sx={{ color:'#880e4f'}} variant='body2'>Biaya Admin : {biayaAdmBukalapak}% Rp {biayaAdminBukalapak.toLocaleString()}</Typography>
            </Stack>
            
            <Divider sx={{m:1}}/>

            <Box sx={{backgroundColor:'#880e4f',  borderRadius: '5px', p:1 ,mt:1}}>
            <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanBukalapak.toLocaleString()}</Typography>
            </Box>
    


        </Box>



        <Box id="blibli" sx={{backgroundColor:'#e3f2fd', p:1, borderRadius:3, m:1, mb:2, p:2}}>

          <Typography sx={{ color:'#0d47a1'}} variant='subtitle2'>Blibli  </Typography>
          <Divider sx={{m:1}}/>

          <Typography sx={{ color:'#0d47a1'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
          <Typography sx={{ color:'#0d47a1'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
          <Typography sx={{ color:'#0d47a1'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>

          <Stack>
          <Typography sx={{ color:'#0d47a1'}} variant='body2'>Biaya Admin : {biayaAdmBlibli}% Rp {biayaAdminBlibli.toLocaleString()}</Typography>
          </Stack>

          <Divider sx={{m:1}}/>

          <Box sx={{backgroundColor:'#0d47a1',  borderRadius: '5px', p:1 ,mt:1}}>
          <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanBlibli.toLocaleString()}</Typography>
          </Box>

    </Box>




    <Box id="lazada" sx={{backgroundColor:'#ede7f6', p:1, borderRadius:3, m:1, mb:2, p:2}} id="lazada">

      <Typography sx={{ color:'#311b92'}} variant='subtitle2'>Lazada  </Typography>
      <Divider sx={{m:1}}/>

      <Typography sx={{ color:'#311b92'}} variant='body2'>Harga Jual : Rp {Number(hitungCuan.hargaProduk)?.toLocaleString()}</Typography>
      <Typography sx={{ color:'#311b92'}} variant='body2'>Harga Modal : Rp {Number(hitungCuan.hargaModal)?.toLocaleString()}</Typography>
      <Typography sx={{ color:'#311b92'}} variant='body2'>Voucher yg digunakan : {Number(hitungCuan.voucherProduk)?.toLocaleString()}</Typography>

      <Stack>
      <Typography sx={{ color:'#311b92'}} variant='body2'>Biaya Admin : {biayaAdmLazada}% Rp {biayaAdminLazada.toLocaleString()}</Typography>
      </Stack>

      <Divider sx={{m:1}}/>

      <Box sx={{backgroundColor:'#311b92',  borderRadius: '5px', p:1 ,mt:1}}>
      <Typography sx={{ color:'white', textAlign:'center'}} variant='subtitle2'>Total Cuan : Rp {cuanLazada.toLocaleString()}</Typography>
      </Box>

      </Box>




        </Container>
    </>
  )
}
