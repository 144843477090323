import { Alert, Box, Button, Divider, IconButton, Modal, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate,useParams } from 'react-router-dom';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import dayjs from 'dayjs';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect } from 'react';
import { titleCase } from "title-case";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export const ListAngsuran = (props) => {

    let navigate = useNavigate()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius:5,
      };

    let [textCopy, setTextCopy] = useState('Copy')
    let [textCopy2, setTextCopy2] = useState('Copy')

    let newHargaNormal = Number(props.produk.hargaNormal)
    let newCashback = Number(props.produk.cashback)
    let newHargaFinal = Number(props.produk.hargaFinal)
    let bungaKredivo = (2.6 * newHargaFinal) / 100 
    let adminFee3Bulan = (3 * newHargaFinal) / 100
    let cicilanKredivo3Bulan = Math.ceil(((newHargaFinal + adminFee3Bulan) / 3) / 10) * 10;
    let cicilanKredivo6Bulan = Math.ceil(((newHargaFinal / 6) + bungaKredivo) / 10) * 10;
    let cicilanKredivo12Bulan = Math.ceil(((newHargaFinal / 12) + bungaKredivo) / 10) * 10;

    let bungaHci = (3.2 * newHargaFinal) / 100;
    let cicilanHci6Bulan = Math.ceil(((newHargaFinal / 6) + bungaHci) / 10) * 10;
    let cicilanHci9Bulan = Math.ceil(((newHargaFinal / 9) + bungaHci) / 10) * 10;
    let cicilanHci12Bulan = Math.ceil(((newHargaFinal / 12) + bungaHci) / 10) * 10;
    let cicilanHci15Bulan = Math.ceil(((newHargaFinal / 15) + bungaHci) / 10) * 10;
    let cicilanHci18Bulan = Math.ceil(((newHargaFinal / 18) + bungaHci) / 10) * 10;

    // PROMO BUNGA RENDAH HOMECREDIT START HERE
    let biayaSubsidi5Bulan = ((5 * newHargaFinal) / 100) + 199000;
    let hargaProdukSetelahSubsidi = newHargaFinal + biayaSubsidi5Bulan
    let cicilanPromoHci5Bulan = (Math.ceil(((hargaProdukSetelahSubsidi / 5))  / 10) * 10)+8000;

    let biayaSubsidi8Bulan = ((6 * newHargaFinal) / 100) + 199000;
    let hargaProduk8BulanSetelahSubsidi = newHargaFinal + biayaSubsidi8Bulan
    let cicilanPromoHci8Bulan = (Math.ceil(((hargaProduk8BulanSetelahSubsidi / 8))  / 10) * 10)+8000;
    // PROMO BUNGA RENDAH HOMECREDIT END HERE

    // HITUNGAN KARTU KREDIT START HERE
    let biayaAdmKartuKredit6Bln = (1.5 * newHargaFinal) / 100
    let biayaAdmKartuKredit12Bln = (3.5 * newHargaFinal) / 100

    let cicilanKartuKredit3Bulan = (Math.ceil(newHargaFinal / 3))
    let cicilanKartuKredit6Bulan = (Math.ceil(((newHargaFinal + biayaAdmKartuKredit6Bln) / 6)/10)*10)
    let cicilanKartuKredit12Bulan = (Math.ceil(((newHargaFinal + biayaAdmKartuKredit12Bln) / 12)/10)*10)







    console.log('Cicilan ',cicilanKredivo3Bulan,cicilanKredivo6Bulan,cicilanKredivo12Bulan, cicilanPromoHci5Bulan)
    console.log('Cicilan Kartu Kredit ', cicilanKartuKredit3Bulan, cicilanKartuKredit6Bulan, cicilanKartuKredit12Bulan)
      

    let hargaCash = `${titleCase(props.produk.namaProduk)}
${props.produk.hargaNormal&&`Harga : ${newHargaNormal.toLocaleString()}`}${props.produk.cashback&&`\nCashback : ${newCashback.toLocaleString()}
Harga Spesial : ${props.produk.hargaFinal.toLocaleString()}`}${props.produk.garansi&&`\nGaransi : ${props.produk.garansi}`}${props.produk.free&&`\nFree : ${props.produk.free}`}${props.produk.freeKlaim&&`\nFree Klaim : ${props.produk.freeKlaim}`}${props.produk.periodePromo?`\nPeriode Promo : ${dayjs(props.produk.periodeMulai).format('DD-MM-YYYY')} s/d ${dayjs(props.produk.periodeAkhir).format('DD-MM-YYYY')}`:''}${props.produk.linkProduk&&`\nInfo Produk : ${props.produk.linkProduk}`}${props.produk.keterangan&&`\nKeterangan : ${props.produk.keterangan}`}
`

 const [listCicilan, setListCicilan] = useState(undefined)

 useEffect(()=>{

  if(props.produk.hargaFinal>=500000 && props.produk.hargaFinal<1000000){
    let listCicilan1 = `${hargaCash}
Cicilan Tanpa Kartu Kredit
Estimasi Cicilan Kredivo
DP : 0
3X : ${cicilanKredivo3Bulan.toLocaleString()}
6X : ${cicilanKredivo6Bulan.toLocaleString()}
12X : ${cicilanKredivo12Bulan.toLocaleString()}

Cicilan Kartu Kredit (Via Blibli)
3x : ${cicilanKartuKredit3Bulan.toLocaleString()}
6x : ${cicilanKartuKredit6Bulan.toLocaleString()}
12x : ${cicilanKartuKredit12Bulan.toLocaleString()}
`
  setListCicilan(listCicilan1)
  
  }else if (props.produk.hargaFinal>=1000000){
  
      let listCicilan2 = `${hargaCash}
Cicilan Tanpa Kartu Kredit
Estimasi Cicilan Kredivo
DP : 0
3x : ${cicilanKredivo3Bulan.toLocaleString()}
6x : ${cicilanKredivo6Bulan.toLocaleString()}
12x : ${cicilanKredivo12Bulan.toLocaleString()}
  
Estimasi Cicilan Homecredit
DP : 0
6x : ${cicilanHci6Bulan.toLocaleString()}
9x : ${cicilanHci9Bulan.toLocaleString()}
12x : ${cicilanHci12Bulan.toLocaleString()}
15x : ${cicilanHci15Bulan.toLocaleString()}
18x : ${cicilanHci18Bulan.toLocaleString()}

Promo Bunga Rendah Homecredit (Gratis 1 Kali Cicilan)
DP : 0
6x : ${cicilanPromoHci5Bulan.toLocaleString()} (Cukup Bayar 5x)
9x : ${cicilanPromoHci8Bulan.toLocaleString()} (Cukup Bayar 8x)

Cicilan Kartu Kredit 0% (Via Blibli)
3x : ${cicilanKartuKredit3Bulan.toLocaleString()}
6x : ${cicilanKartuKredit6Bulan.toLocaleString()}
12x : ${cicilanKartuKredit12Bulan.toLocaleString()}
`
  setListCicilan(listCicilan2)
  
  }
 },[])


    const onCopyText = (tombol) => {
      console.log(tombol)
      if(tombol===1){
        setTextCopy('Tercopy')
      }
      else if(tombol===2){setTextCopy2('Tercopy')}
      
      setTimeout(() => {
        setTextCopy('Copy');
        setTextCopy2('Copy');
      }, 1000);
    };


    

  return (
    <>
        <Modal
        open={props.lihatangsuran}
        onClose={()=> props.setLihatAngsuran(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <Stack sx={style}>
          <Stack direction="row" justifyContent="flex-end">
          <IconButton  aria-label="delete" size="small" >
              <CloseIcon fontSize="inherit" onClick={()=> props.setLihatAngsuran(false)}/>
          </IconButton>
          </Stack>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            {titleCase(props.produk.namaProduk)}
          </Typography>
          {props.produk.produkDiscontinue&&<Alert sx={{mt:1,mb:1}} severity="error">Produk sudah discontinue</Alert>}
          {props.statusPromo === 1&&<Alert sx={{mb:1}}  severity="error">Promo sudah berakhir segera ganti harga</Alert>}
          {!props.produk.linkProduk&&<Alert sx={{mb:1}} severity="warning">Link produk tidak tersedia</Alert>}
          <Typography  variant="caption">
            Update Terakhir : {props.produk.lastUpdate}
          </Typography>

          <Divider sx={{mt:1, mb:1}}/>

          

          <Stack direction="flex" gap={1}>
            <Typography  variant="subtitle2" >
              Kategori :
            </Typography>
            <Stack direction="flex" alignItems={'center'}>
            <Typography  variant="subtitle2"  color='secondary' sx={{cursor:'pointer'}} onClick={()=>{navigate(`/kategori/${props.produk.kategori}`)}} >
            {titleCase(props.produk.kategori)}
            </Typography>
            <HighlightAltIcon fontSize='small' color='secondary'/>
            </Stack>
          </Stack>

          <Stack direction="flex" gap={1}>
            <Typography  variant="subtitle2" >
              Brand :
            </Typography>
            <Stack direction="flex"   alignItems={'center'}>
            <Typography  variant="subtitle2"  color='secondary' sx={{cursor:'pointer'}} onClick={()=>{navigate(`/brands/${props.produk.brand}`)}}>
            {titleCase(props.produk.brand)}
            </Typography>
            <HighlightAltIcon fontSize='small' color='secondary'/>
            </Stack>
          </Stack>

          {props.produk.linkProduk&&<Stack direction="flex" gap={1}>
            <Typography  variant="subtitle2" >
              Link Produk :
            </Typography>
            <Stack direction="flex"   alignItems={'center'}>
            <Typography  variant="subtitle2"  color='secondary' sx={{cursor:'pointer'}} onClick={() => window.open(props.produk.linkProduk?props.produk.linkProduk:'#')}>
            {props.produk.linkProduk}
            </Typography>
            <HighlightAltIcon fontSize='small' color='secondary'/>
            </Stack>
          </Stack>}

          {props.produk.produkEtalase.length !== 0&&<Typography variant="subtitle2">Etalase : </Typography>}
          {props.produk.produkEtalase?.map((el,index)=>{
            return(
            <>
            <Stack direction="flex">
            <Typography Typography  variant="caption"  color='secondary' sx={{cursor:'pointer'}} onClick={()=>{navigate(`/etalase/${el}`)}}>- {titleCase(el)}</Typography>
            <HighlightAltIcon fontSize='small' color='secondary'/>
            </Stack>
            </>
            )
          })}




          <Divider sx={{mt:1, mb:1}}/>






    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Harga Cash" {...a11yProps(0)} />
          <Tab label="List Cicilan" {...a11yProps(1)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          
        <Typography variant="body2" className="td" >
              Harga : {Number(props.produk.hargaNormal).toLocaleString()}
        </Typography>
          {props.produk.cashback&&
            <>
              <Typography variant="body2">
                Cashback : {Number(props.produk.cashback).toLocaleString()}
              </Typography>
              <Typography variant="body2">
                Harga Spesial : {props.produk.hargaFinal.toLocaleString()}
              </Typography>
            </>}
          {props.produk.garansi&&<Typography variant="body2">Garansi : {props.produk.garansi}</Typography>}
          {props.produk.periodePromo&&<Typography variant="body2">
            Periode Promo : {dayjs(props.produk.periodeMulai).format('DD-MM-YYYY')} s/d {dayjs(props.produk.periodeAkhir).format('DD-MM-YYYY')}
          </Typography>}
          {props.produk.free&&<Typography variant="body2">Free : {props.produk.free}</Typography>}
          {props.produk.freeKlaim&&<Typography variant="body2">Free Klaim : {props.produk.freeKlaim}</Typography>}

          {props.produk.linkProduk&&<Typography variant="body2">Info Produk : {props.produk.linkProduk}</Typography>}
          {props.produk.keterangan&&<Typography variant="body2">Keterangan : {props.produk.keterangan}</Typography>}
          
          

          



          <CopyToClipboard  text={hargaCash} onCopy={()=>onCopyText(1)}>
            <Button fontSize='small' variant="contained" sx={{m:'auto', display:'flex', mt:1}} >{textCopy}</Button>
          </CopyToClipboard>

          <Divider sx={{mt:1, mb:1}}/>

      </TabPanel>
      
      <TabPanel value={value} index={1}>
        
      {props.produk.hargaFinal>=500000?
          <>
          <Typography variant="body2">
            Estimasi Cicilan Kredivo 
          </Typography>
          <Typography variant="body2">
            DP : 0
          </Typography>
          <Typography variant="body2">
            3x : {cicilanKredivo3Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            6x : {cicilanKredivo6Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            12x :{cicilanKredivo12Bulan.toLocaleString()}
          </Typography>
          
          {props.produk.hargaFinal>=1000000&&
          <>
          <Typography variant="body2" sx={{mt:1}}>
            Estimasi Cicilan Homecredit 
          </Typography>
          <Typography variant="body2">
            DP : 0
          </Typography>
          <Typography variant="body2">
            6x : {cicilanHci6Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            9x : {cicilanHci9Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            12x : {cicilanHci12Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            15x :  {cicilanHci15Bulan.toLocaleString()}
          </Typography>
          <Typography variant="body2">
            18x : {cicilanHci18Bulan.toLocaleString()}
          </Typography>

            <Divider sx={{mt:1}}></Divider>
            <Typography variant="body2" sx={{mt:1}}>
              Promo Bunga Rendah Homecredit (Gratis 1 Kali Cicilan)
            </Typography>
            <Typography variant="body2">
              DP : 0
            </Typography>
            <Typography variant="body2">
              6x : {cicilanPromoHci5Bulan.toLocaleString()} (Cukup Bayar 5x)
            </Typography>
            <Typography variant="body2">
              9x : {cicilanPromoHci8Bulan.toLocaleString()} (Cukup Bayar 8x)
            </Typography>

  
           <Box sx={{backgroundColor:'#ffebee', p:1, mt:1}}>
              <Typography variant="body2" fontWeight={'bold'} sx={{color:'#b71c1c'}}>Khusus Promo Bunga Rendah Terdapat Biaya Layanan</Typography>
              <Typography variant="body2"  sx={{color:'#b71c1c'}}>Tenor 6 Bulan : 5% dari Harga Produk + 199.000 menjadi <Box component='span' fontWeight={'bold'}>Rp {hargaProdukSetelahSubsidi.toLocaleString()}</Box></Typography> 
              <Typography variant="body2"  sx={{color:'#b71c1c'}}>Tenor 9 Bulan : 6% dari Harga Produk + 199.000 menjadi <Box component='span' fontWeight={'bold'}>Rp {hargaProduk8BulanSetelahSubsidi.toLocaleString()}</Box></Typography> 
            </Box>
            
            <Divider sx={{mt:1, mb:1}}/>


            <Box>
              <Typography variant="body2">Cicilan Kartu Kredit 0% (<Box underline="hover" component={Link} to="/kartu-kredit#blibli">via Blibli</Box>)</Typography>
              <Typography variant="body2">3x : {cicilanKartuKredit3Bulan.toLocaleString()}</Typography>
              <Typography variant="body2">6x : {cicilanKartuKredit6Bulan.toLocaleString()}</Typography>
              <Typography variant="body2">12x : {cicilanKartuKredit12Bulan.toLocaleString()}</Typography>
            </Box>

          </>}
          
       
          
          <CopyToClipboard  text={listCicilan} onCopy={()=>onCopyText(2)}>
            <Button fontSize='small' variant="contained" sx={{m:'auto', display:'flex', mt:1}} >{textCopy2}</Button>
          </CopyToClipboard>

          

          </>
        :<Alert severity="info">Harga Produk dibawah 500rb - Tidak Dapat di Kredit</Alert>
        }
      </TabPanel>


    </Box>







      

        
        <IconButton  aria-label="delete" size="small" >
              <Button fullWidth fontSize='small' variant="outlined" sx={{m:'auto', mt:1}} onClick={()=> props.setLihatAngsuran(false)} >Close</Button>
          </IconButton>

        </Stack>
      </Modal>



    </>
  )
}
