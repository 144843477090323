import { Chip, Container, Stack, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


export const EtalaseProduk = () => {

    

  return (
    <>
    <Container>
      
    </Container>
    </>
  )
}
