import { Typography } from '@mui/material'
import { Container, Stack } from '@mui/system'
import React from 'react'
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Link } from 'react-router-dom';

export const Masterplan = () => {
  return (
    <Container>
        <Stack sx={{mb:20}}>
            <Typography variant="h6" sx={{m:'auto', mt:3, fontWeight:'bold'}}>Master Plan</Typography>
            <Typography variant="caption" textAlign={'right'}>Last Update : 27 Maret 2023</Typography>


            <Typography variant="body2" sx={{mt:2}}>Berikut merupakan target prioritas Galaxy Camera. Prioritas diurutkan berdasarkan urutan. Master Plan akan diupdate secara berkala.</Typography>
            
            <Stack direction={'row'} sx={{mt:2}} alignItems={'center'} gap={1}>
            <ModeStandbyIcon/>
            <Typography fontWeight={'bold'} >Meningkatkan Jumlah Ulasan Google <Typography variant='body2' sx={{color:'grey'}}>To : Sales & Kepala Toko</Typography></Typography>
            </Stack>
            <Typography variant='body2' sx={{mt:1}}>Saat ini rating toko kita cukup tertinggal jika dibanding komptetitor, jadi sangat diharapkan bagi setiap sales  secara aktif meminta ulasan pelanggan di Google setiap kali menyelesaikan penjualan. Peran Kepala Toko akan sangat berpengaruh besar dengan tujuan peningkatan ulasan Google ini, karena jika dari Kepala Toko sendiri jika tidak membudayakan melakukan permintaan ulasan / terlalu gengsi maka sales lainnya akan otomatis melakukan hal serupa.</Typography>
            <Typography variant='body2'>Mari kita tingkatkan pelayanan yang lebih baik, sehingga pelanggan merasa senang untuk mensupport kita dengan memberi ulasan positif di Google</Typography>
            <Typography variant='body2'>Lihat panduan lengkap tentang etika meminta ulasan Google <Typography sx={{textDecoration:'none'}} variant="body2" component={Link} to="/ulasan-google">disini</Typography></Typography>
        

            <Stack direction={'row'} sx={{mt:2}} alignItems={'center'} gap={1}>
            <ModeStandbyIcon/>
            <Typography fontWeight={'bold'} >Pengumpulan Data Yang Lebih Akurat <Typography variant='body2' sx={{color:'grey'}}>To : Sales & Kepala Toko</Typography></Typography>
            </Stack>
            <Typography variant='body2' sx={{mt:1}}>Setiap data report penjualan (Sumber Customer) yang kalian isi sangatlah berharga, karena dari data tersebut bisa digunakan untuk memaksimalkan iklan ke platform yang lebih tepat sasaran.</Typography>
            <Typography variant='body2'>Usahakan untuk mengisi sumber customer sejujur mungkin, jika tidak berhasil mendapatkan sumber customer, harap bisa diisi tidak sempat ditanyakan.</Typography>
            <Typography variant='body2'>Lebih akrab kita ke customer lebih memudahkan kita untuk menggali informasi dari mana customer tersebut mengetahui Toko kita.</Typography>
        

            <Stack direction={'row'} sx={{mt:2}} alignItems={'center'} gap={1}>
            <ModeStandbyIcon/>
            <Typography fontWeight={'bold'} >Mengupload Story Secara Berkala<Typography variant='body2' sx={{color:'grey'}}>To : Staff Office, Staff Gudang, Sales & Kepala Toko</Typography></Typography>
            </Stack>
            <Typography variant='body2' sx={{mt:1}}>Bagi toko / staff yang sudah memiliki HP operasional terutama jika sudah memiliki banyak kontak tersimpan di HP tersebut. Sangat disayangkan jika kita tidak pernah memanfaatkan database (kontak customer) yang sudah disave di HP Toko, dengan mengupload Postingan Story WA secara berkala (setiap hari)</Typography>
            <Typography variant='body2'>Story akan tersedia setiap hari di Group WA Creative. Jika tidak masuk dalam grup tersebut harap hubungi Admin untuk minta dimasukkan ke grup tersebut. Setiap view akan sangat berharga dan berpotensi menghasilkan transaksi dimasa depan.</Typography>
            <Typography variant='body2'>Dengan aktifnya kita mengupload Story setiap hari menandakan bahwa toko kita masih eksis dan selalu update dengan promo-promo terbaru. Sehingga customer berpotensi akan berbelanja kembali di Toko kita atau merekomendasikan Toko kita ke kerabat terdekat.</Typography>
            <Typography variant='body2'>Mari kita membentuk relasi yang lebih dekat kepada Customer dan memperbanyak save kontak pelanggan. Karena dengan sukses nya masterplan ini akan membuka peluang masterplan kita untuk seperti toko-toko kompetitor lainnya yaitu : <Typography sx={{color:'#616161'}} variant='body2' fontWeight={'bold'}>Membuka Kelas Fotografi / Fototrip / Training dari Prinsipal</Typography> </Typography>



            <Stack direction={'row'} sx={{mt:2}} alignItems={'center'} gap={1}>
            <ModeStandbyIcon/>
            <Typography fontWeight={'bold'} >Peningkatan Kualitas Konten di Instagram dan Youtube <Typography variant='body2' sx={{color:'grey'}}>To : Konten Kreator & Editor</Typography></Typography>
            </Stack>
            <Typography variant='body2' sx={{mt:1}}>Jika dibanding periode terdahulu, jumlah like disetiap postingan instagram kita terlihat signifikan jauh.</Typography>
            <Typography variant='body2'>Lebih sering melakukan research atau mencari inspirasi dari postingan kompetitor atau toko online populer lainnya (contoh : erafone, fujishop, focusnusantara, dll). Tidak lupa juga untuk melihat kembali postingan Galaxy terdahulu (scroll ke masa lalu), untuk bahan masukan menjadi lebih baik.</Typography>
            <Typography variant='body2'>Selalu menggunakan kualitas gambar terbaik.</Typography>


            <Stack direction={'row'} sx={{mt:2}} alignItems={'center'} gap={1}>
            <ModeStandbyIcon/>
            <Typography fontWeight={'bold'} >Upload Produk Lebih Banyak dan Perbaikan Produk Secara Rutin<Typography variant='body2' sx={{color:'grey'}}>To : Staff Marketplace</Typography></Typography>
            </Stack>
            <Typography variant='body2' sx={{mt:1}}>Kita sebagai penjual tentu akan masanya dimana produk tertentu menjadi produk best seller selama beberapa periode, namun setiap produk pun ada masanya.</Typography>
            <Typography variant='body2'>Kita sangat perlu untuk mencari terus produk-produk yang berpotensi menjadi produk best seller kita, dengan terus meresearch kompetitor.</Typography>
            <Typography variant='body2'>Dan tidak lupa untuk selalu mengecek berkala produk yang sudah kita upload lama, karena selalu ada celah untuk bisa kita improvisasi menjadi lebih baik. Manfaat kan tool Bot Research untuk meneliti atau mengoreksi produk kita sendiri (harga, style editan, berat dll).</Typography>


            <Typography variant='body2' sx={{mt:2, fontStyle:'italic'}} >Semoga dengan adanya masterplan ini dan tentunya kerja sama dari kalian, Toko kita  akan mengarah menjadi lebih baik dari sebelumnya. Jika kita tidak merubah atau memperbaiki pelayanan atau cara berjualan, maka akan sulit buat toko kita berkembang lebih jauh. Kita tidak akan pernah berhenti untuk memperbaiki diri dan akan selalu terbuka untuk menerima masukan atau mencoba hal-hal baru. Harapan tersebut juga kepada kalian untuk memiliki mental yang serupa.</Typography>

        </Stack>
    </Container>

    
  )
}
