import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container, Stack } from '@mui/system';


export const TermCondition = () => {
  return (
    <>
      <Container>
      <Stack sx={{mt:3, mb:20}}>
      <Stack direction={'row'} sx={{m:'auto', mb:2}} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} variant="h5" >PERATURAN DASAR TENTANG KEPEGAWAIAN</Typography>
        </Stack>
        <Box sx={{mt:3}}>

        <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Tata Krama dan Sopan Santun [PENTING]</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    <Typography sx={{my:1}}>1. Sopan Santun: Setiap staf diharapkan untuk berinteraksi dengan sopan santun dan hormat terhadap rekan kerja, tanpa memandang jabatan atau posisi.</Typography>
                    <Typography sx={{my:1}}>2. Keterbukaan dan Komunikasi Efektif: Staf diharapkan untuk berkomunikasi dengan jujur, terbuka, dan tepat waktu. Ini mencakup memberikan masukan secara konstruktif dan menghindari konflik yang tidak perlu.</Typography>
                    <Typography sx={{my:1}}>3. Kerahasiaan dan Privasi: Staf diharapkan untuk menghormati privasi dan kerahasiaan informasi pribadi atau profesional sesama staf, serta informasi rahasia perusahaan.</Typography>
                    <Typography sx={{my:1}}>4. Kerja Sama Tim: Staf diharapkan untuk bekerja sama dalam tim, mendukung satu sama lain, dan menghargai kontribusi individu masing-masing.</Typography>
                    <Typography sx={{my:1}}>5. Penggunaan Bahasa yang Sesuai: Bahasa yang digunakan di lingkungan kerja harus sopan, tidak kasar, dan tidak merendahkan. Ini juga termasuk penggunaan bahasa yang inklusif dan menghormati keberagaman.</Typography>
                    <Typography sx={{my:1}}>6. Penyelesaian Konflik secara Dewasa: Staf diharapkan untuk menyelesaikan konflik secara dewasa dan profesional, menghindari konfrontasi yang tidak perlu, dan mencari solusi yang memuaskan semua pihak yang terlibat.</Typography>
                    <Typography sx={{my:1}}>7. SPEAK UP : Staf yang mendapatkan perlakuan tidak mengenakan baik secara kata maupun perbuatan jangan takut untuk speak up atau laporkan kepada atasan.</Typography>
                    <Typography sx={{my:1}}>8. SAY NO TO BULLY : Pembullyan yang mengakibatkan ketidaknyamanan pada rekan staf akan ditindak tegas berupa teguran hingga pemutusan kerja, demi menjaga suasana kerja nyaman dan kondusif.</Typography>
                    <Typography sx={{my:1}}>9. SIAPA YANG TOXIC ? : Jika komunikasi anda terhadap mayoritas staff baik artinya bukan anda yang toxic, jika anda memiliki kesulitan komunikasi terhadap mayoritas staff atau mayoritas staff takut berkomunikasi kepada anda, ada kemungkinan besar anda yang toxic. Harap segera evaluasi dan perbaiki cara berkomunikasi anda demi situasi kerja yang nyaman dan kondusif.</Typography>
                    <Typography sx={{my:1}}>9. Pendengar yang Baik : Staf diharapkan untuk menjadi pendengar yang empatik, memperhatikan dengan seksama dan memahami perspektif serta perasaan rekan kerja mereka.</Typography>

                    
                  </Stack>
                </AccordionDetails>
              </Accordion>




              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Jadwal Kerja dan Off Day</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    <Typography sx={{my:1}}>1. Setiap bulannya, karyawan berhak mendapatkan 4 kali libur yang ditentukan oleh kantor. Hari libur akan diumumkan di Grup Jadwal Off Whatsapp.</Typography>
                    <Typography sx={{my:1}}>2. Jika seorang karyawan mengajukan izin tidak masuk pada hari kerja, maka jatah liburnya akan dipotong atau gaji akan dipotong sesuai jumlah hari libur yang diambil.</Typography>
                    <Typography sx={{my:1}}>3. Karyawan yang tidak hadir tanpa izin pada hari kerja akan dikenakan denda sebesar tiga kali lipat gaji harian karyawan.</Typography>
                    <Typography sx={{my:1}}>4. Karyawan tidak diperbolehkan menggeser hari libur yang telah ditentukan oleh kantor.</Typography>
                    <Typography sx={{my:1}}>5. Jam kerja tersedia dalam dua shift, yaitu shift pertama dari jam 9 pagi sampai jam 6 sore dan shift kedua dari jam 10 siang sampai jam 7 malam.</Typography>
                    <Typography sx={{my:1}}>6. Karyawan diharapkan untuk segera melakukan absensi pada saat jam kerja selesai. Korupsi waktu atau tidak melakukan absensi pada saat jam kerja selesai akan ditindak dengan pemotongan gaji.</Typography>
                    <Typography sx={{my:1}}>7. Karyawan yang melakukan lembur akan mendapatkan bayaran sebesar Rp 14.000 per jam.</Typography>
                    <Typography sx={{my:1}}>8. Seluruh karyawan wajib datang tepat waktu dan sudah siap bekerja pada saat jam operasional dimulai.</Typography>
                    <Typography sx={{my:1}}>9. Jika seorang karyawan terlambata lebih dari 10 menit, maka akan dianggap tidak hadir pada hari tersebut. Karyawan yang terlambat akan dikenakan denda sebesar Rp 10.000 per menit terlambat.</Typography>
                    <Typography sx={{my:1}}>10. Jika dalam sebulan karyawan terlambat lebih dari 3 kali, karyawan tersebut akan diberikan peringatan dan konsekuensi yang lebih lanjut bisa berupa pemotongan gaji atau pengurangan jumlah libur bulanan.</Typography>
                    <Typography sx={{my:1}}>11. Karyawan baru tidak mendapatkan jatah libur pada minggu pertama kerja di perusahaan.</Typography>
                    <Typography sx={{my:1}}>12. Karyawan yang memutuskan tidak mengambil jatah off day akan diganti dengan Rp 150.000 per hari.</Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Ketentuan Cuti</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{my:1}}>1. Karyawan yang sudah bekerja selama satu tahun atau lebih berhak mendapatkan jatah cuti selama 7 hari dalam setahun.</Typography>
            <Typography sx={{my:1}}>2. Pengajuan cuti harus dilakukan minimal dua minggu sebelumnya, kecuali untuk kasus darurat seperti terkena musibah. Pengajuan cuti yang tidak sesuai dengan waktu yang telah ditentukan dapat ditolak oleh perusahaan.</Typography>
            <Typography sx={{my:1}}>3. Tidak diperbolehkan untuk mengambil cuti bersamaan dengan rekan kerja lainnya.</Typography>
            <Typography sx={{my:1}}>4. Cuti yang tidak diambil akan diganti dengan uang sebesar Rp 100.000 per hari cuti yang tidak diambil.</Typography>
            <Typography sx={{my:1}}>5. Perusahaan berhak menolak pengajuan cuti jika waktu cuti yang diajukan dianggap tidak tepat dan tidak memungkinkan untuk dilakukan.</Typography>
            <Typography sx={{my:1}}>6. Pengajuan cuti tidak menjamin 100% akan disetujui oleh perusahaan.</Typography>

            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Peraturan Jam Istirahat</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{my:1}}>1. Setiap karyawan berhak untuk mendapatkan waktu istirahat selama jam kerja.</Typography>
            <Typography sx={{my:1}}>2. Durasi jam istirahat adalah maksimal 60 menit setiap harinya.</Typography>
            <Typography sx={{my:1}}>3. Jam istirahat tidak boleh digabungkan dengan jam masuk kerja ataupun jam pulang kerja.</Typography>
            <Typography sx={{my:1}}>4. Jika karyawan membutuhkan waktu istirahat yang lebih dari 60 menit, maka harus mendapatkan persetujuan dari atasan langsung terlebih dahulu.</Typography>
            <Typography sx={{my:1}}>5. Karyawan yang melanggar aturan waktu istirahat akan dikenakan sanksi sesuai dengan peraturan yang berlaku diperusahaan.</Typography>
            <Typography sx={{my:1}}>6. Sanksi yang dikenakan dapat berupa peringatan tertulis, penurunan prestasi kerja, atau bahkan pemutusan hubungan kerja tergantung pada tingkat kesalahan yang dilakukan oleh karyawan tersebut.</Typography>
            <Typography sx={{my:1}}>7. Karyawan diharapkan untuk memperhatikan waktu istirahat dengan baik sehingga dapat meningkatkan produktivitas dan kualitas kerja.</Typography>

            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Ketentuan Resign</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{my:1}}>1. Karyawan yang ingin resign harus memberikan pemberitahuan minimal 1 bulan sebelumnya kepada manajemen perusahaan. Untuk resign dibawah 1 bulan wajib mendapatkan persetujuan atasan.</Typography>
            <Typography sx={{my:1}}>2. Karyawan yang ingin resign harus mengajari anak baru pengganti mengenai pekerjaan yang akan diwariskan dan memberikan transfer pengetahuan dengan maksimal sebelum karyawan tersebut meninggalkan perusahaan.</Typography>
            <Typography sx={{my:1}}>3. Jika karyawan memutuskan untuk resign secara dadakan atau dibawah 1 bulan atau tanpa memberikan pemberitahuan sebelumnya, maka uang gaji dan lembur tidak akan dibayarkan</Typography>
            <Typography sx={{my:1}}>4. Karyawan yang resign wajib mengembalikan seragam / properti lainnya yang telah diberikan. Jika seragam tidak dikembalikan, maka gaji dan lembur tidak akan dibayarkan.</Typography>
            <Typography sx={{my:1}}>5. Karyawan yang ingin resign wajib membuat surat bukti pengunduran diri.</Typography>
            <Typography sx={{my:1}}>6. Harap dicatat bahwa manajemen perusahaan berhak mengevaluasi pengunduran diri karyawan dan menentukan kapan karyawan akan diberhentikan dari pekerjaannya setelah masa resign minimal satu bulan telah berakhir.</Typography>
            <Typography sx={{my:1}}>7. Karyawan yang ingin resign wajib menyelesaikan pekerjaan yang terpending atau urusan yang belum selesai.</Typography>
            <Typography sx={{my:1}}>8. Jika ketentuan point 1 sampai dengan 8 tidak terpenuhi maka perusahaan tidak akan membayar sisa gaji atau uang lembur serta tidak akan memberikan support permintaan surat paklaring.</Typography>

            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Tanggung Jawab Barang Hilang</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{my:1}}>1. Jika ada barang yang hilang, maka seluruh karyawan yang bertanggung jawab atas barang tersebut akan menanggung kerugian secara bersama-sama sesuai divisi yang bersangkutan.</Typography>
            <Typography sx={{my:1}}>2. Setiap karyawan diwajibkan untuk menjaga barang-barang perusahaan dengan baik dan tidak diperkenankan untuk meminjam atau mengambil barang perusahaan tanpa izin yang sah.</Typography>
            <Typography sx={{my:1}}>3. Apabila ada salah satu karyawan yang terbukti bersalah atau terlibat dalam kejadian hilangnya barang, maka akan ditindaklanjuti secara hukum dan dapat dikenakan sanksi sesuai peraturan yang berlaku diperusahaan.</Typography>
            <Typography sx={{my:1}}>4. Semua karyawan diharapkan untuk melaporkan hilangnya barang kepada atasan langsung dan tim keamanan perusahaan secepat mungkin, sehingga dapat dilakukan investigasi dan tindakan yang diperlukan untuk mengatasi kejadian tersebut.</Typography>
            </AccordionDetails>
          </Accordion>



          <Typography sx={{my:2}}>Kebijakan ini bertujuan untuk menjaga keamanan dan keteraturan dalam perusahaan serta memberikan jaminan perlindungan terhadap barang-barang perusahaan. Semua karyawan diharapkan untuk mematuhi kebijakan ini dan berkontribusi dalam menjaga keamanan dan keberlangsungan perusahaan. </Typography>


        </Box>
        </Stack>
      </Container>
    </>
  )
}
