import { Container, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'

export const PanduanCrewStore = () => {

  
  const [pass, setLihatPass] = useState('Lihat')

  const handleClick = () => {
    if (pass === 'Lihat') {
      setLihatPass('Stacy1989');
    } else {
      setLihatPass('Lihat');
    }
  };


  return (
    <>
      <Container>
        <Stack sx={{mt:3, mb:20}}>

        <Stack direction={'row'} sx={{m:'auto', mb:2}} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} variant="h5" >CREW STORE</Typography>
        </Stack>

        <Stack sx={{mt:2}}>
        <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>JOB DESK</Typography>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Melayani Penjualan di Toko baik secara offline maupun online.</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Melakukan Report Penjualan.</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Membalas Ulasan Google setiap hari.</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Membalas Pertanyaan pada Google setiap hari.</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Menjadwalkan Live Marketplace secara berkala.</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Mengupload Story untuk keperluan promosi.</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Mengupload Produk / Update Harga pada marketplace.</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' >Membuat Konten Produk untuk keperluan promosi.</Typography>
        </Stack>

        </Stack>




        
        <Stack sx={{mt:2}}>
        <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>METODE PEMBAYARAN TERSEDIA</Typography>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Cash / Tunai</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Debit</Typography>
        </Stack>

        <Stack direction={'column'} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Kartu Kredit</Typography>

            <Typography variant='body2'>Transaksi Kartu Kredit tersedia 3 Opsi</Typography>
            <Typography variant='body2'>- Gesek Langsung di Mesin EDC Toko</Typography>
            <Typography variant='body2'>- Transaksi Melalui BLIBLI INSTORE</Typography>
            <Typography variant='body2'>- Transaksi Melalui Marketplace seperti Tokopedia / Shopee</Typography>
        </Stack>

        <Stack direction={'column'} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Tanpa Kartu Kredit</Typography>

            <Typography variant='body2'>Transaksi tanpa kartu kredit tersedia banyak pilihan</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Homecredit (Wajib ketoko)</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Kredivo (Bisa dari Rumah)</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Indodana (Wajib ketoko)</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Akulaku (Bisa dari Rumah)</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Aeon Kredit (Wajib ketoko)</Typography>
            <Typography variant='body2' fontWeight={'bold'}>- Shopee Paylater (Bisa dari Rumah)</Typography>


            <Typography variant='body2' sx={{mt:1}}>Setiap pengajuan cicilan tanpa kartu kredit hasilnya adalah 50:50, selalu menawarkan opsi leasing lain jika pengajuan leasing A ditolak. Semakin banyak leasing yang ditawarkan ke customer, semakin besar kemungkinan transaksi akan berhasil.</Typography>
            <Typography variant='body2' sx={{mt:1}}>Contoh : Jika Customer ke toko ingin kredit kamera Canon 1500D melalui Homecredit, selalu siapkan plan B jika Homecredit di tolak langsung tawarkan mencoba pengajuan leasing lain di Kredivo, Indodana, dan lain lain.</Typography>

            <Stack direction={'column'} bgcolor={'#e1f5fe'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' sx={{mt:1}} fontWeight={'bold'}>Cara Pengajuan Homecredit.</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Pengajuan Homecredit hanya bisa dilakukan ditoko langsung.</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Download Aplikasi Home Credit pada HP Customer, untuk mengetahui limit Kredit yang didapatkan.</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Jika Customer sudah berhasil mendapatkan limit Kredit silahkan panggil petugas Homecredit ke toko untuk dibantu proses berikutnya.</Typography>
            </Stack>

            <Stack direction={'column'} bgcolor={'#e1f5fe'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' sx={{mt:1}} fontWeight={'bold'}>Cara Kredit melalui Kredivo.</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Download aplikasi Kredivo di Play Store (Android) dan App Store (iOS).</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Pilih Daftar Kredivo dan pilih tipe aplikasi Basic atau Premium (Arahkan Customer untuk pilih Premium karena bisa dapat limit hingga 50jt, sedangkan basic hanya bisa dapat limit maksimal 3jt).</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Upload KTP, isi data yang diperlukan dan kirim pengajuan.</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Tunggu proses verifikasi (biasanya kurang lebih 30 menit, maks 24 jam)</Typography>
            <Typography variant='body2' sx={{mt:1}} >- Setelah disetujui, klik link yang dikirimkan Kredivo via SMS/Email untuk aktifkan akunmu.</Typography>

            </Stack>



        </Stack>

        </Stack>

        <Divider sx={{m:2}}></Divider>

        <Stack sx={{mt:2}}>
        <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>MESIN EDC</Typography>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>BCA</Typography>
            <Typography variant='body2' fontWeight={'bold'}>Kode Settlement : 3636</Typography>
        </Stack>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>BNI</Typography>
            <Typography variant='body2' fontWeight={'bold'}>Kode Settlement : 1400</Typography>
        </Stack>
        
        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>BRI</Typography>
            <Typography variant='body2' fontWeight={'bold'}>Kode Settlement : 4560</Typography>
        </Stack>

        <Typography variant='body2' sx={{mt:1}}>Setiap selesai melakukan Debit wajib langsung melakukan Settlement pada mesin EDC.</Typography>

        </Stack>

        <Divider sx={{m:2}}></Divider>

        <Stack sx={{mt:2}}>
        <Typography variant='body2' fontWeight={'bold'} sx={{color:'#ff5722'}}>QRIS BCA</Typography>

        <Stack direction={'row'} alignItems={'center'} gap={1} bgcolor={'#f5f5f5'} sx={{p:1, mt:1, borderRadius:"10px"}}>
            <Typography variant='body2' fontWeight={'bold'}>Pass Tablet :</Typography>
            <Typography sx={{color:'#ff5722'}} onClick={handleClick} variant='body2' fontWeight={'bold'}>{pass}</Typography>
        </Stack>
        </Stack>

        <Divider sx={{m:2}}></Divider>

        







        </Stack>
        </Container>
    </>
  )
}
